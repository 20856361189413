import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-number-cycle',
  templateUrl: './number-cycle.component.html',
  styleUrls: ['./number-cycle.component.css']
})
export class NumberCycleComponent implements OnInit {

  @Input()
  defaultIndex: number;

  @Input()
  items: Array<any>;

  currentIndex: number;

  @Output()
  item: EventEmitter<string>;

  constructor() {
    this.item = new EventEmitter<string>();
  }

  ngOnInit(): void { }

  reset(): void {
    this.currentIndex = this.defaultIndex;
    this.emitIndex();
  }

  decrement(): void {
    if (this.items && this.currentIndex > 0) {
      this.currentIndex--;
      this.emitIndex();
    }
  }

  increment(): void {
    if (this.items && this.currentIndex < (this.items.length - 1)) {
      this.currentIndex = this.defaultIndex;
      this.emitIndex();
    }
  }

  private emitIndex(): void {
    if (this.items) {
      this.item.emit(this.items[this.currentIndex]);
    }
  }

}
