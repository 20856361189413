import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NumberCycleComponent } from './number-cycle.component';

@NgModule({
  declarations: [
    NumberCycleComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    NumberCycleComponent
  ],
  providers: []
})
export class NumberCycleModule {}
