import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class PopupService {

    constructor(private modalService: NgbModal) { }

    public open(content: any) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
    }

    public close() {
        if (!this.modalService.hasOpenModals()) {
            return;
        }
        this.modalService.dismissAll();
    }

}
