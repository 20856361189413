import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';

import { TreeFeature } from './tree-feature.interface';
import { Router } from '@angular/router';
import { UserService, viewPerms } from '../../services/user/user.service';
import { User } from '../../services/user/user.interface';

declare var $: any;

@Component({
  selector: 'app-tree-side-bar',
  templateUrl: './tree-side-bar.component.html',
  styleUrls: ['./tree-side-bar.component.css']
})
export class TreeSideBarComponent implements OnInit {

  @Input()
  public user?: User;

  @Input()
  public logo?: string;

  @Input()
  public logoClass?: string;

  @Input()
  public features?: Array<TreeFeature>;

  @Input()
  public selected?: string;

  @Output()
  public changeSelection?: EventEmitter<TreeFeature>;

  public viewPerms = viewPerms;

  constructor(
    private router: Router,
    private userService: UserService) {
    this.changeSelection = new EventEmitter<TreeFeature>();
  }

  ngOnInit(): void {
    this.selected = this.router.routerState.snapshot.url;
  }

  select(feature: TreeFeature) {
    if (feature.route){
      this.selected = feature.route;
      this.changeSelection.emit(feature);
    }
  }

  parentHasRequiredPerm(feature: TreeFeature): boolean {
    return !feature.requiredPerm || (feature.requiredPerm && this.userService.canAccess(feature.requiredPerm));
  }

  childHasRequiredPerm(featureChild: TreeFeature): boolean {
    return !featureChild.requiredPerm || (featureChild.requiredPerm && this.userService.canAccess(featureChild.requiredPerm));
  }

  onToggleCollapse() {
    $( "#app" ).removeClass( "sidebar-open" );
  }
}
