import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-percentage-spent-resolve',
  templateUrl: './percentage-spent-resolve.component.html',
  styleUrls: ['./percentage-spent-resolve.component.css']
})
export class PercentageSpentResolveComponent implements OnInit {

  @Input()
  spend: number;

  constructor() { }

  ngOnInit(): void {}

}
