import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable()
export class NavigationService {

  constructor(
    private location: Location,
    private router: Router) {}

  go(url: string) {
    this.router.navigateByUrl(url);
  }

  bootstrap() {
    this.router.initialNavigation();
  }

  clearQueryParams() {
    this.location.replaceState(this.location.path().split('?')[0], '');
  }
}
