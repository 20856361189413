import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PaymentService } from '../../services/payments/payment.service';

import { StripeCardComponent } from '../stripe-card/stripe-card.component';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner..module';

@NgModule({
  declarations: [
    StripeCardComponent
  ],
  imports: [
    CommonModule,
    LoadingSpinnerModule
  ],
  exports: [
    StripeCardComponent
  ],
  providers: [
    PaymentService
  ]
})
export class StripeCardModule {}


