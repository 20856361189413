import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppConfiguration } from '../../app.configuration';

import { Product } from './product.interface';
import { ExchangeRate } from './exchange-rate.interface';

@Injectable()
export class ProductService {

  constructor(
    private httpClient: HttpClient,
    private appConfiguration: AppConfiguration
  ) {}

  getProducts(owner: string): Observable<Array<Product> | Array<string>> {
    return this.httpClient.get<Array<Product>>(
      this.appConfiguration.endpoints['getProducts'] + '?productOwner=' + owner,
      {
        observe: 'body',
        responseType: 'json'
      }
    ).pipe(
      map((data: object) => {
        return data['data'];
      }),
      map((products: Array<Product>) => {
        return products;
      }),
      catchError(this.handleError)
    );
  }

  getCurrencies(requestedCurrencies: Array<string>): Observable<Array<string> | Array<string>> {
    return this.httpClient.get<Array<string>>(
      this.appConfiguration.endpoints['currencies'],
      {
        observe: 'body',
        responseType: 'json'
      }
    ).pipe(
      map((currencies: Array<string>) => {
        return currencies.filter((currency: string) => {
          const isvalid = requestedCurrencies.indexOf(currency) !== -1;
          return isvalid;
        });
      }),
      catchError(this.handleError)
    );
  }

  getFxRate(base: string, quoted: string, date: string): Observable<ExchangeRate | Array<string>> {
    return this.httpClient.get<Array<ExchangeRate>>(
      this.appConfiguration.endpoints['getFxRate'] + '?base=' + base + '&quoted=' + quoted + '&date=' + date,
      {
        observe: 'body',
        responseType: 'json'
      }
    ).pipe(
      map((data: object) => {
        return data['data'];
      }),
      map((exchangeRate: ExchangeRate) => {
        return exchangeRate;
      }),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse): Observable<Array<string>> {
    return of((error.error.errors));
  }
}
