import { Injectable } from '@angular/core';
import { ProductService } from './product.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';

import { AppConfiguration } from '../../app.configuration';
import { Product } from './product.interface';

@Injectable()
export class ProductResolverService implements Resolve<Array<Product> | Array<string>>  {

  constructor(
    private appConfiguration: AppConfiguration,
    private productService: ProductService) { }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Array<Product> | Array<string>> {
    return this.productService.getProducts(this.appConfiguration.clientSettings.client);
  }

}
