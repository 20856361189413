import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

import { AppConfiguration } from '../../app.configuration';

@Component({
  selector: 'app-global-date-range',
  templateUrl: './global-date-range.component.html',
  styleUrls: ['./global-date-range.component.css']
})
export class GlobalDateRangeComponent implements OnInit {

  @Input()
  public startDate: Date;

  @Input()
  public endDate: Date;

  @Output()
  public dateIntervalChanged: EventEmitter<{ startDate: Date, endDate: Date }>;

  @Output()
  public endDateChanged: EventEmitter<Date>;

  public minStartDate: Date;

  public minEndDate: Date;

  public maxStartDate: Date;

  public maxEndDate: Date;

  public range: FormGroup;

  constructor(private appConfiguration: AppConfiguration) {
    const defaultStartDate =
      this.appConfiguration.
        
        clientSettings.
        defaultStartDate;

    const today = new Date();

    this.minStartDate = defaultStartDate;
    this.maxStartDate = today;

    this.minEndDate = defaultStartDate;
    this.maxEndDate = today;

    this.dateIntervalChanged = new EventEmitter<{ startDate: Date, endDate: Date }>();

  }

  ngOnInit() {
    this.range = new FormGroup({
      startDate: new FormControl(this.startDate),
      endDate: new FormControl(this.endDate)
    });

  }

  changeStartDate(event: MatDatepickerInputEvent<Date>) {
    if (this.hasValidDateRange()) {
      this.dateIntervalChanged.emit({
        startDate: this.range.controls['startDate'].value,
        endDate: this.range.controls['endDate'].value
      });
    }
  }

  changeEndDate(event: MatDatepickerInputEvent<Date>) {
    if (this.hasValidDateRange()) {
      this.dateIntervalChanged.emit({
        startDate: this.range.controls['startDate'].value,
        endDate: this.range.controls['endDate'].value
      });
    }
  }

  private resetStartDate() {
    this.range.patchValue({ startDate: this.minStartDate });
  }

  private resetEndDate() {
    this.range.patchValue({ endDate: this.minEndDate });
  }

  private hasValidDateRange(): boolean {
    const valid = !!(this.range.controls['startDate'].value &&
      this.range.controls['endDate'].value);
    return valid;
  }

}
