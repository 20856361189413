import { payableCurrencySymbols } from "./currency-symbols";

export const SUPPLIER_SUMMARY_GRID_DATA = {
    endpoint: '/organization/%organizationId%/report',
    columnDefinitions: [
        {
            title: 'Supplier',
            data: 'supplierName',
            orderable: true,
            searchable: false,
            render: (data, type, row, meta) => {
                let content = '<td><div><span>';
                content += '<span style="display: inline-block;"><div data-toggle="popover" data-title="';
                content += (data !== null && data !== 'tbd') ? data : '';
                content += '" data-content="<div>';
                content += (row['address']['city'] && row['address']['city'] !== 'tbd') ? row['address']['city'] : '';
                content += '</div><div>';
                content += (row['address']['country'] && row['address']['country'] !== 'tbd') ? row['address']['country'] : '';
                content += '</div><div>';
                content += row['industryName'];
                content += '</div>" data-placement="right" data-html="true" data-trigger="hover">';
                content += data;
                content += '</div></span>';

                return content;
            }
        },
        {
            title: 'Amount',
            data: 'amount',
            orderable: true,
            searchable: false,
            render: (data, type, row, meta) => {
                const currencySymbol = payableCurrencySymbols[row.currency];
                return currencySymbol + ' ' + parseFloat(data).toFixed(2);
            }
        },
        {
            title: 'Currency',
            data: 'currency',
            orderable: false,
            searchable: false,
            visible: false
        },
        {
            title: 'Industry',
            data: 'industryName',
            orderable: true,
            searchable: false
        },
        {
            title: 'Total tCO2e',
            data: 'allScopesTotal',
            orderable: true,
            searchable: false,
            render: (data, type, row, meta) => {
                return parseFloat(data).toFixed(4);
            }
        },
        {
            title: 'Scope 1 tCO2e',
            data: 'scope1Total',
            orderable: true,
            searchable: false,
            render: (data, type, row, meta) => {
                return parseFloat(data).toFixed(4);
            }
        },
        {
            title: 'Scope 2 tCO2e',
            data: 'scope2Total',
            orderable: true,
            searchable: false,
            render: (data, type, row, meta) => {
                return parseFloat(data).toFixed(4);
            }
        },
        {
            title: 'Scope 3 tCO2e',
            data: 'scope3Total',
            orderable: true,
            searchable: false,
            render: (data, type, row, meta) => {
                return parseFloat(data).toFixed(4);
            }
        }
    ],
    dataSrc: {
        recordsTotalAlias: 'totalSuppliers',
        recordsFilteredAlias: 'filteredSuppliers',
        recordsAlias: 'suppliers'
    },
    queryDefinition: {
        type: 'supplierSummary'
    },
    defaultSortColumn: 'amount',
    defaultSortOrder: 'desc',
    sortCriteriaMap: {
        supplierName: 'supplierName',
        amount: 'amount',
        currency: 'currency',
        industryName: 'industryName',
        allScopesTotal: 'allScopesTotal',
        scope1Total: 'scope1Total',
        scope2Total: 'scope2Total',
        scope3Total: 'scope3Total'
    }
};
