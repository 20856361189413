import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { AppConfiguration } from './app.configuration';
import { AddOrganizationModule } from './components/add-organization/add-organization.module';
import { AppRoutingModule } from './app-routing.module';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ChooseOrganizationModule } from './components/choose-organization/choose-organization.module';
import { ConfirmAccountModule } from './components/confirm-account/confirm-account.module';
import { HomeDataResolverService } from './services/home/home-data-resolver.service';
import { HomeModule } from './components/home/home.module';
import { NotFoundModule } from './components/not-found/not-found.module';
import { OrganizationService } from './services/organization/organization.service';
import { NavigationService } from './services/navigation/navigation.service';
import { PayModule } from './components/pay/pay.module';
import { PurchaseConfirmationModule } from './components/purchase-confirmation/purchase-confirmation.module';
import { ReportsService } from './services/reports/reports.service';
import { RequestPasswordModule } from './components/request-password/request-password.module';
import { ResetPasswordModule } from './components/reset-password/reset-password.module';
import { RegisterModule } from './components/register/register.module';
import { RedirectComponent } from './components/redirect/redirect.component';
import { ScriptService } from './services/script/script.service';
import { ServerErrorModule } from './components/server-error/server-error.module';
import { SignInModule } from './components/sign-in/sign-in.module';
import { UserService } from './services/user/user.service';
import { PricingModule } from './components/pricing/pricing.module';
import { SelectCompanyModule } from './components/select-company/select-company.module';

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];

@NgModule({
  declarations: [
    AppComponent,
    RedirectComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    ChooseOrganizationModule,
    ConfirmAccountModule,
    HomeModule,
    HttpClientModule,
    NotFoundModule,
    PayModule,
    RegisterModule,
    RequestPasswordModule,
    ResetPasswordModule,
    RouterModule,
    PricingModule,
    PurchaseConfirmationModule,
    SignInModule,
    RouterModule,
    ServerErrorModule,
    SelectCompanyModule,
    NgbModule
  ],
  providers: [
    AppConfiguration,
    HomeDataResolverService,
    OrganizationService,
    NavigationService,
    ReportsService,
    ScriptService,
    UserService,
    httpInterceptorProviders,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
