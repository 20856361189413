export const WORKING_SUPPLIERS_GRID_DATA = {
    endpoint: '/working-organization/%organizationId%/status-report/%affiliate%',
    confirmEvent: 'confirm-resolve-uploaded-supplier',
    columnDefinitions: [
        {
            title: 'Imported Supplier Name',
            data: 'suppliedOrganizationName',
            orderable: true,
            searchable: false
        },
        {
            title: 'Official Supplier Name',
            data: 'officialSupplierName',
            orderable: true,
            searchable: false,
            render: (data, type, row, meta) => {
                if (data) {
                    return '<td><div><span>' +
                        '<a id="cell-event#confirm-resolve-uploaded-supplier#' + meta['row'] + '#' + meta['col'] +
                        '" href="javascript:void(0);">' + data + '</a>' +
                        '</span></div></td>';
                }
                return '<td><div><span>' +
                    '<i id="cell-event#confirm-resolve-uploaded-supplier#' + meta['row'] + '#' + meta['col'] + '" class="fa fa-question-circle" style="font-size:18px; cursor:pointer; color:#3498DB;"></i>' +
                    '</span></div></td>';
            }
        },
        {
            title: 'Invitation Sent?',
            data: 'invitationSent',
            orderable: true,
            searchable: false,
            visible: false,
            render: (data, type, row, meta) => {

                // Can't send invitations if the supplier has not been resolved
                if (!row['officialSupplierName']) {
                    return '<td><div><span title="Resolve the supplier in order to send an invitation">' +
                        '<i class="fa fa-times-circle" style="font-size:18px; cursor:pointer; color:#3498DB;"></i>' +
                        '</span></div></td>';
                }

                // Can send invitations if the supplier resolved and we have not yet sent
                if (row['officialSupplierName'] && !data) {
                    return '<td><div><span>' +
                        '<a id="cell-event-invitation#' +
                        meta['row'] +
                        '#' +
                        meta['col'] +
                        '" href="javascript:void(0);">' +
                        '<td><div><span><i class="fa fa-envelope-o" style="font-size:18px; cursor:pointer; color:#3498DB;"></i>' +
                        '</a>' +
                        '</span></div></td>';
                }

                // Can resend invitations if already sent
                if (data) {
                    const caption = 'Resend';

                    return '<td><div><span>' +
                        '<a id="cell-event-invitation#' +
                        meta['row'] +
                        '#' +
                        meta['col'] +
                        '" href="javascript:void(0);">' +
                        caption +
                        '</a>' +
                        '</span></div></td>';
                }

            }
        },
        {
            title: 'Registered?',
            data: 'registered',
            orderable: true,
            searchable: false,
            visible: false,
            render: (data, type, row, meta) => {
                return '<td><div><span><i class="fa ' +
                    (data ? 'fa-check-circle' : 'fa-times-circle') +
                    '" style="font-size:18px; cursor:pointer; color:#3498DB;"></i></span></div></td>';
            }
        },
        {
            title: 'Certified?',
            data: 'certified',
            orderable: true,
            searchable: false,
            visible: false,
            render: (data, type, row, meta) => {
                return '<td><div><span><i class="fa ' +
                    (data ? 'fa-check-circle' : 'fa-times-circle') +
                    '" style="font-size:18px; cursor:pointer; color:#3498DB;"></i></span></div></td>';
            }
        }
    ],
    queryDefinition: {},
    defaultSortColumn: 'suppliedOrganizationName',
    defaultSortOrder: 'asc',
    sortCriteriaMap: {
        suppliedOrganizationName: 'suppliedOrganizationName',
        officialSupplierName: 'officialSupplierName',
        invitationSent: 'invitationSent',
        registered: 'registered',
        certified: 'certified'
    }
};
