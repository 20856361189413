import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';

import { AddOrganizationComponent } from './add-organization.component';
import { AddOrganizationRoutingModule } from './add-organization-routing.module';
import { CountryResolverService } from '../../services/organization/country-resolver.service';
import { IndustryResolverService } from '../../services/organization/industry-resolver.service';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner..module';
import { SpecialCategoriesComponent } from './special-categories/special-categories.component';
import { UserService } from '../../services/user/user.service';
import { UserResolverService } from '../../services/user/user-resolver.service';

@NgModule({
  declarations: [
    AddOrganizationComponent,
    SpecialCategoriesComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    LoadingSpinnerModule,
    NgbTypeaheadModule,
    ReactiveFormsModule,
  ],
  exports: [
    AddOrganizationComponent
  ],
  providers: [
    CountryResolverService,
    IndustryResolverService,
    UserService,
    UserResolverService,
  ]
})
export class AddOrganizationModule { }


