import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve} from '@angular/router';
import { Observable } from 'rxjs';

import { Industry } from './industry.interface';
import { OrganizationService } from './organization.service';

@Injectable()
export class IndustryResolverService implements Resolve<Array<Industry> | Array<string>> {

  constructor(private organizationService: OrganizationService) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Array<Industry> | Array<string>> {
    return this.organizationService.getIndustries();
  }
}
