import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, AfterViewInit } from '@angular/core';

import { ScriptService } from './services/script/script.service';
import { UserService } from './services/user/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private scriptService: ScriptService,
    private userService: UserService) {}

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    this.loadScripts();
    this.configureClient();
  }

  private loadScripts() {
    // Need to control the loading order of the scripts
    this.scriptService.load('vendor').then(() => {
      this.scriptService.load('app').then(() => {
        this.scriptService.load('dataTables').then(() => {}).
            catch(error => console.log(error));
      }).catch(error => console.log(error));
    })
    .catch(error => console.log(error));
  }

  private configureClient() {
    const hostName = document.location.hostname;
    this.userService.refreshClient(hostName);
  }
}
