// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  client: 'insight',
  production: 'false',
  stripePublishableKey: 'pk_test_aws6ADuDNEpoj1eRDdrwdtfo',
  stripePricingPageKey: 'prctbl_1MBQ7w2B8okBWvxaI5o6UK0U',
  accountingRedirect: 'https://dev.insight2.co2analytics.com/home/setup/connect-suppliers',
  defaultStartDate: new Date('2013-01-02'),
  apis: {
    users: 'https://dev.api.co2analytics.com/api/v1',
    impacts: 'https://dev.impacts.co2analytics.com/api/v1',
    ecomm: 'https://dev.fin.co2analytics.com/api/v1'
  },
  bypassCode: '%bypassCode%',
  requirePaymentOnClaimCompany: true,
  checkoutSuccessUrl: 'https://dev.insight2.co2analytics.com/payment-redirect?session_id={CHECKOUT_SESSION_ID}',
  checkoutFailedUrl: 'https://dev.insight2.co2analytics.com/error',
  paymentPlans: [
    {
      displayName: 'Startup',
      name: '%startup%',
      logo: 'assets/spa-insight/img/Seed2.png',
      currency: ''
    },
    {
      displayName: 'Small',
      name: '%smallBusiness%',
      logo: 'assets/spa-insight/img/Sprout2.png',
      currency: ''
    },
    {
      displayName: 'Medium',
      name: '%medium%',
      logo: 'assets/spa-insight/img/Shrub2.png',
      currency: ''
    },
    {
      displayName: 'Premium',
      name: '%premium%',
      logo: 'assets/spa-insight/img/tree2.png',
      currency: ''
    }
  ]
};
