import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AppConfiguration } from '../../app.configuration';
import { UserService } from '../../services/user/user.service';
import { NavigationService } from '../../services/navigation/navigation.service';


@Component({
    selector: 'app-request-password',
    templateUrl: './request-password.component.html',
    styleUrls: ['./request-password.component.css']
})
export class RequestPasswordComponent implements OnInit {

    public errors: Array<string>;

    public requestPasswordForm: FormGroup;

    public succeeded: boolean;

    public working = false;

    public logo: string;

    public logoClass: string;

    constructor(
        private formBuilder: FormBuilder,
        private appConfiguration: AppConfiguration,
        private userService: UserService,
        private navigationService: NavigationService) {

        this.errors = [];
        this.succeeded = false;

        this.requestPasswordForm = this.formBuilder.group({
            email: ['', Validators.required]
        });

        this.logo = this.appConfiguration.logo;

        this.logoClass = this.appConfiguration.logoClass;
    }

    ngOnInit() {
      this.userService.signOut();
    }

    goToSignIn(): void {
        this.navigationService.go('/sign-in');
    }

    goToSignUp(): void {
        this.navigationService.go('/sign-up');
    }

    requestPassword(): void {
        this.working = true;

        this.resetErrors();

        this.userService.requestPassword(
            this.requestPasswordForm.get('email').value,
            this.appConfiguration.clientSettings.client
        ).subscribe(
            (data: Array<string>) => {
                this.handleRequestPasswordResponse(data);
            }
        );
    }

    onKeyUp(event: any): void {
        this.succeeded = false;
    }

    private resetErrors() {
        this.errors = [];
    }

    private parseErrors(data) {
        if (data && data.length) {
            this.errors = data;
            return true;
        }
        return false;
    }

    private handleRequestPasswordResponse(data: Array<string>) {
        this.working = false;
        if (!this.parseErrors(data)) {
            this.succeeded = true;
        }
    }

}
