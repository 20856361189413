import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ConfirmAccountComponent } from './components/confirm-account/confirm-account.component';
import { HomeComponent } from './components/home/home.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { PricingComponent } from './components/pricing/pricing.component';
import { PurchaseConfirmationComponent } from './components/purchase-confirmation/purchase-confirmation.component';
import { RedirectComponent } from './components/redirect/redirect.component';
import { RegisterComponent } from './components/register/register.component';
import { RequestPasswordComponent } from './components/request-password/request-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SelectCompanyComponent } from './components/select-company/select-company.component';
import { ServerErrorComponent } from './components/server-error/server-error.component';
import { SignInComponent } from './components/sign-in/sign-in.component';

import { HomeDataResolverService } from './services/home/home-data-resolver.service';
import { CurrencyResolverService } from './services/product/currency-resolver.service';

const routes: Routes = [
  {
    path: 'sign-in',
    component: SignInComponent
  },
  {
    path: 'sign-up',
    component: RegisterComponent
  },
  {
    path: 'select-organization',
    component: SelectCompanyComponent
  },
  {
    path: 'confirm-account/:code',
    component: ConfirmAccountComponent
  },
  {
    path: 'reset-password/:code',
    component: ResetPasswordComponent
  },
  {
    path: 'request-password',
    component: RequestPasswordComponent
  },
  {
    path: 'pricing',
    component: PricingComponent
  },
  {
    path: 'purchase-confirmation',
    component: PurchaseConfirmationComponent
  },
  {
    path: 'pay/:organizationId',
    loadChildren: () => import('./components/pay/pay.module').then(m => m.PayModule)
  },
  {
    path: 'payment-redirect',
    component: RedirectComponent
  },
  {
    path: 'home',
    component: HomeComponent,
    resolve: {
      home: HomeDataResolverService,
      currencies: CurrencyResolverService
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./components/dashboard/insight-dashboard.module').
          then(m => m.InsightDashboardModule)
      },
      {
        path: 'admin',
        loadChildren: () => import('./components/admin/admin.module').
          then(m => m.AdminModule)
      },
      {
        path: 'setup/getting-started',
        loadChildren: () => import('./components/getting-started/getting-started.module').
          then(m => m.GettingStartedModule)
      },
      {
        path: 'setup/connect-suppliers',
        loadChildren: () => import('./components/connect-suppliers/connect-suppliers.module').
          then(m => m.ConnectSuppliersModule)
      },
      {
        path: 'footprint-details/suppliers',
        loadChildren: () => import('./components/supplier-summary/supplier-summary.module').
          then(m => m.SupplierSummaryModule)
      },
      {
        path: 'footprint-details/transactions',
        loadChildren: () => import('./components/supply-chain-summary/supply-chain-summary.module').
          then(m => m.SupplyChainSummaryModule)
      },
      {
        path: 'footprint-details/products',
        loadChildren: () => import('./components/product-summary/product-summary.module').
          then(m => m.ProductSummaryModule)
      },
      {
        path: 'take-action/earn-badges',
        loadChildren: () => import('./components/certifications/certifications.module').
          then(m => m.CertificationsModule)
      },
      {
        path: 'take-action/engage-suppliers',
        loadChildren: () => import('./components/engage-suppliers/engage-suppliers.module').
          then(m => m.EngageSuppliersModule)
      },
      {
        path: '**',
        redirectTo: 'setup/getting-started',
        pathMatch: 'full'
      }
    ],
  },
  {
    path: 'choose-organization',
    loadChildren: () => import('./components/choose-organization/choose-organization.module').then(m => m.ChooseOrganizationModule)
  },
  {
    path: 'not-found',
    component: NotFoundComponent
  },
  {
    path: 'error',
    component: ServerErrorComponent
  },
  {
    path: '',
    redirectTo: 'sign-in',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'not-found',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule {}


