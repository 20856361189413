import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AddOrganizationModule } from '../add-organization/add-organization.module';
import { ChooseOrganizationComponent } from './choose-organization.component';
import { ChooseOrganizationRoutingModule } from './choose-organization-routing.module';

@NgModule({
  declarations: [
    ChooseOrganizationComponent
  ],
  imports: [
    CommonModule,
    ChooseOrganizationRoutingModule,
    AddOrganizationModule
  ],
  exports: [
    ChooseOrganizationComponent
  ],
  providers: []
})
export class ChooseOrganizationModule { }


