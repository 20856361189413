import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter, Inject, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';

import { Organization } from '../../services/organization/organization.interface';
import { OrganizationService } from '../../services/organization/organization.service';
import { PaymentService } from '../../services/payments/payment.service';
import { PopupService } from '../../services/popup/popup.service';
import { PortalSession } from '../../services/payments/portal-session.interface';
import { User } from '../../services/user/user.interface';
import { UserService, viewPerms } from '../../services/user/user.service';

import { EarnedBadges } from '../../services/organization/earned-badges.interface';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @ViewChild('organizationList')
  private organizationListTemplateRef: TemplateRef<any>;

  @Input()
  firstName?: string;

  @Input()
  lastName?: string;

  @Input()
  organizationName?: string;

  @Input()
  currencies;

  @Input()
  reportingCurrency;

  @Input()
  earnedBadges?: EarnedBadges;

  @Input()
  user?: User;

  @Output()
  signOut: EventEmitter<boolean>;

  @Output()
  currencySelected: EventEmitter<string>;

  updating = false;

  hasPurchaseDetails = false;

  selectableCurrencies = [];

  selectableOrgs = [];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private organizationService: OrganizationService,
    private paymentService: PaymentService,
    private popupService: PopupService,
    private router: Router,
    private userService: UserService) {
    this.signOut = new EventEmitter<boolean>();
    this.currencySelected = new EventEmitter<string>();

    this.hasPurchaseDetails = this.userService.canAccess(viewPerms.purchaseDetails);
  }

  ngOnInit(): void {
    this.rebuildSelectableCurrencies(this.reportingCurrency);
    this.rebuildSelectableOrgs(this.organizationName);
  }

  logOut(): void {
    this.signOut.emit(true);
  }

  selectCurrency(currency: string) {
    this.currencySelected.emit(currency);
    this.rebuildSelectableCurrencies(currency);
  }

  manageAccount() {
    const organization: Organization =
      this.organizationService.organization.getValue();
    const returnUrl: string = window.location.href;

    this.paymentService.createPortalSession(organization.id, returnUrl).
      subscribe((portalSession: PortalSession) => {
        this.document.location.href = portalSession.url;
      });
  }

  onToggleCollapse() {
    $( "#app" ).toggleClass( "sidebar-open" );
  }

  openOrganizationList() {
    this.popupService.open(this.organizationListTemplateRef);
  }

  switchOrganization(org) {
    const returnUrl: string = window.location.href;

    this.organizationService.getOrganization(org.organizationId)
      .subscribe(async (organization: Organization) => {
        if (this.user) {
          this.user.preferences.selectedOrganizationId = org.organizationId;
          await this.userService.updateUser(this.user);
          this.document.location.href = returnUrl;
        }
      });
  }

  private rebuildSelectableCurrencies(selectedCurrency) {
    this.selectableCurrencies = [];

    this.currencies.forEach(currency => {
      if (selectedCurrency !== currency) {
        this.selectableCurrencies.push(currency);
      }
    });
  }

  private rebuildSelectableOrgs(organizationName) {
    this.selectableOrgs = [];

    this.user.organizations.forEach(organization => {
      if (organizationName !== organization.organizationName) {
        this.selectableOrgs.push(organization);
      }
    });
  }
}
