import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CertificationsPanelModule } from '../certifications-panel/certifications-panel.module';
import { HeaderComponent } from './header.component';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner..module';
import { PopupService } from '../../services/popup/popup.service';

@NgModule({
  declarations: [
    HeaderComponent
  ],
  imports: [
    CommonModule,
    LoadingSpinnerModule,
    CertificationsPanelModule
  ],
  exports: [
    HeaderComponent
  ],
  providers: [
    PopupService
  ]
})
export class HeaderModule {}
