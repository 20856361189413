import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AppConfiguration } from '../../app.configuration';
import { NavigationService } from '../../services/navigation/navigation.service';
import { PasswordValidation } from '../../validators/password-match.validator';
import { UserService } from '../../services/user/user.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

    public errors: Array<string>;

    public resetPasswordForm: FormGroup;

    public resetCode: string;

    public succeeded: boolean;

    public working = false;

    public logo: string;

    public logoClass: string;

    constructor(
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private appConfiguration: AppConfiguration,
        private navigationService: NavigationService,
        private userService: UserService) {

        this.errors = [];

        this.succeeded = false;

        this.resetPasswordForm = this.formBuilder.group({
            password: ['', Validators.required],
            passwordMatch: ['', Validators.required]
        },
        {
            validator: PasswordValidation.MatchPassword // your validation method
        });

        this.route.params.subscribe(params => {
            this.resetCode = params.code;
        });

        this.logo = this.appConfiguration.logo;

        this.logoClass = this.appConfiguration.logoClass;

    }

    ngOnInit() {
      this.userService.signOut();
    }

    goToSignIn(): void {
        this.navigationService.go('/sign-in');
    }

    goToSignUp(): void {
        this.navigationService.go('/sign-up');
    }

    resetPassword(): void {
        this.working = true;

        this.resetErrors();

        this.userService.resetPassword(
            this.resetCode,
            this.resetPasswordForm.get('password').value
        ).subscribe(
            (data: Array<string>) => {
                this.handleResetPasswordResponse(data);
            }
        );
    }

    private resetErrors() {
        this.errors = [];
    }

    private parseErrors(data) {
        if (data && data.length) {
            this.errors = data;
            return true;
        }
        return false;
    }

    private handleResetPasswordResponse(data: Array<string>) {
        this.working = false;
        if (!this.parseErrors(data)) {
            this.succeeded = true;
        }
    }

}
