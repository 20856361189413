import { environment } from '../environments/environment';

import { RESOLVE_SUPPLIERS_GRID_DATA } from './components/data-grid/grid-specs/resolve-suppliers.data';
import { SUPPLIER_SUMMARY_GRID_DATA } from './components/data-grid/grid-specs/supplier-summary.data';
import {
  TRANSACTIONS_SUMMARY_GRID_DATA,
  PRODUCTS_SUMMARY_GRID_DATA
} from './components/data-grid/grid-specs/transaction-summary.data';
import { WORKING_SUPPLIERS_GRID_DATA } from './components/data-grid/grid-specs/working-supplier.data';

export const CLIENT_INSIGHT = 'insight';

export class AppConfiguration {
  purchaseKey = 'XD7sx9dgnIs2wwc';

  endpoints: any;

  tokenKey: string;

  userKey: string;

  organizationKey: string;

  logo = 'assets/img/insight-logo-2.png';

  logoClass = 'co2analytics-logo';

  logoClass2 = 'co2analytics-logo';

  defaultRoute = '/sign-in';

  defaultPaymentCurrency = 'GBP';

  baseMeasuremntCurrency = 'USD';

  organizationSearchLimit = 10;

  features = [
    {
      icon: 'fa-solid fa-gauge',
      caption: 'Dashboard',
      route: '/home/dashboard',
    },
    {
      icon: 'fa-solid fa-gears',
      caption: 'Admin',
      route: '/home/admin',
      requiredPerm: 'view-admin'
    },
    {
      icon: 'fa-solid fa-gear',
      caption: 'Setup',
      route: '',
      children: [
        {
          caption: 'Getting Started',
          route: '/home/setup/getting-started'
        },
        {
          caption: 'Connect Suppliers',
          route: '/home/setup/connect-suppliers',
        }
      ]
    },
    {
      icon: 'fa-solid fa-tree',
      caption: 'Footprint Details',
      route: '',
      children: [
        {
          caption: 'By Transaction',
          route: '/home/footprint-details/transactions',
          requiredPerm: 'view-purchase-details'
        },
        {
          caption: 'By Product',
          route: '/home/footprint-details/products',
          requiredPerm: 'view-product-details'
        },
        {
          caption: 'By Supplier',
          route: '/home/footprint-details/suppliers',
          requiredPerm: 'view-supplier-summary'
        },
      ]
    },
    {
      icon: 'fa-solid fa-share',
      caption: 'Take Action',
      route: '',
      children: [
        {
          caption: 'Earn Badges',
          route: '/home/take-action/earn-badges'
        },
        {
          caption: 'Engage Suppliers',
          route: '/home/take-action/engage-suppliers',
          /* requiredPerm: 'view-engage-suppliers' */
        }
      ]
    }
  ];

  payableCurrencies = ['GBP', 'EUR', 'USD'];

  defaultStartDate = '2013-01-01';

  enableDashboardDateSelector = true;

  stripePublishableKey: string;

  stripePricingPageKey: string;

  dataGrids = {};

  topSupplierCount = 5;

  topIndustryCount = 5;

  clientSettings;

  termsAndConditionsLink = "https://support.co2analytics.com/portal/en/kb/articles/terms-and-conditions";

  constructor() {

    this.clientSettings = environment;

    this.dataGrids = {};

    const resolveSuppliersGridData = RESOLVE_SUPPLIERS_GRID_DATA;
    const suppliersSummaryGridData = SUPPLIER_SUMMARY_GRID_DATA;
    const transactionsSummaryGridData = TRANSACTIONS_SUMMARY_GRID_DATA;
    const productsSummaryGridData = PRODUCTS_SUMMARY_GRID_DATA;
    const workingSuppliersGridData = WORKING_SUPPLIERS_GRID_DATA;

    resolveSuppliersGridData.endpoint = this.clientSettings.apis.impacts + resolveSuppliersGridData.endpoint;
    suppliersSummaryGridData.endpoint = this.clientSettings.apis.impacts + suppliersSummaryGridData.endpoint;
    transactionsSummaryGridData.endpoint = this.clientSettings.apis.impacts + transactionsSummaryGridData.endpoint;
    productsSummaryGridData.endpoint = this.clientSettings.apis.impacts + productsSummaryGridData.endpoint;
    workingSuppliersGridData.endpoint = this.clientSettings.apis.impacts + workingSuppliersGridData.endpoint;

    this.dataGrids['resolveSuppliers'] = resolveSuppliersGridData;
    this.dataGrids['supplierSummary'] = suppliersSummaryGridData;
    this.dataGrids['transactionsSummary'] = transactionsSummaryGridData;
    this.dataGrids['productsSummary'] = productsSummaryGridData;
    this.dataGrids['workingSuppliers'] = WORKING_SUPPLIERS_GRID_DATA;

     this.stripePublishableKey = this.clientSettings.stripePublishableKey;
     this.stripePricingPageKey = this.clientSettings.stripePricingPageKey;

    const seed = 263069594;

    const client = (environment as any).client;

    this.tokenKey = (environment as any).client + '-' + seed;
    this.userKey = (environment as any).client + '-' + (seed + 1);
    this.organizationKey = (environment as any).client + '-' + (seed + 2);

    this.endpoints = {
      usersApi: this.clientSettings.apis.users,
      impactsApi: this.clientSettings.apis.impacts,
      ecommApi: this.clientSettings.apis.ecomm,

      getToken: this.clientSettings.apis.users + '/token',
      getUser: this.clientSettings.apis.users + '/user',
      confirmUser: this.clientSettings.apis.users + '/confirmation',
      createUser: this.clientSettings.apis.users + '/user',
      requestPassword: this.clientSettings.apis.users + '/password-reset',
      resetPassword: this.clientSettings.apis.users + '/password-reset',
      userPreferences: this.clientSettings.apis.users + '/user',

      getOrganization: this.clientSettings.apis.impacts + '/organization',
      findOrganizations: this.clientSettings.apis.impacts + '/organization/search',
      createOrganization: this.clientSettings.apis.impacts + '/organization',
      claimOrganization: this.clientSettings.apis.impacts + '/organization/%organizationId%/status',
      industries: this.clientSettings.apis.impacts + '/industry-codes',
      updateConfirmationStatus: this.clientSettings.apis.impacts + '/organization/%organizationId%/supplier/classified',
      importWorkingSupplierReport: this.clientSettings.apis.impacts + '/working-organizations',
      getWorkingSupplierReport: this.clientSettings.apis.impacts + '/working-organization/%organizationId%/status-report/%affiliate%',
      updateWorkingSupplier: this.clientSettings.apis.impacts + '/working-organization',
      inviteSupplier: this.clientSettings.apis.impacts + '/working-organization/invitation',
      summaryReport: this.clientSettings.apis.impacts + '/working-organization/%organizationId%/summary-report/%affiliate%',
      reports: this.clientSettings.apis.impacts + '/report',
      resolveTransactionSupplier: this.clientSettings.apis.impacts + '/organization/%id%/transaction',
      updateReportingCurrency: this.clientSettings.apis.impacts + '/organization/%id%/reporting-currency',
      dataSources: this.clientSettings.apis.impacts + '/data-sources',
      authorizationRequest: this.clientSettings.apis.impacts + '/data-source/authorization-request',
      deleteDataSource: this.clientSettings.apis.impacts + '/data-source/partner-connection',
      getDataSource: this.clientSettings.apis.impacts + '/data-source/partner-connection',
      authorizationDataSource: this.clientSettings.apis.impacts + '/data-source/access-token?',
      syncDataSource: this.clientSettings.apis.impacts + '/data-source/partner-connection',
      dashboardMetrics: this.clientSettings.apis.impacts + '/report',
      graphData: this.clientSettings.apis.impacts + '/organization/%organizationId%/graph/yearly-footprint',
      updateGlobalReportInterval: this.clientSettings.apis.impacts + '/organization/%organizationId%/report-date-range',
      updateReportingYear: this.clientSettings.apis.impacts + '/organization/%organizationId%/reporting-period',
      createDataConnection: this.clientSettings.apis.impacts + '/organization/%organizationId%/data-connection',
      updateDataConnection: this.clientSettings.apis.impacts + '/organization/%organizationId%/data-connection/%connectionId%',
      getDataConnection: this.clientSettings.apis.impacts + '/organization/%organizationId%/data-connection/%connectionId%',
      insightSummaryReports: this.clientSettings.apis.impacts + '/organization/%organizationId%/report',
      adminState: this.clientSettings.apis.impacts + '/organization/%organizationId%/admin-state',
      pushSuppliers: this.clientSettings.apis.impacts + '/organization/%organizationId%/suppliers/%partner%',
      uploadCompanies: this.clientSettings.apis.impacts + '/companies',
      uploadCompaniesStatus: this.clientSettings.apis.impacts + '/companies/data-connection/%processId%',
      uploadTransactions: this.clientSettings.apis.impacts + '/organization/%organizationId%/transactions',
      uploadTransactionsStatus: this.clientSettings.apis.impacts + '/companies/data-connection/%processId%',
      deleteUploadCompaniesProcess: this.clientSettings.apis.impacts + '/file-upload/%processId%',
      editProductEstimate: this.clientSettings.apis.impacts + '/organization/%organizationId%/transaction/%transactionId%/modifier',

      countries: this.clientSettings.apis.impacts + '/countries',
      currencies: this.clientSettings.apis.ecomm + '/currencies',
      getProducts: this.clientSettings.apis.ecomm + '/products',
      initiatePayment: this.clientSettings.apis.ecomm + '/product-charge',
      updatePayment: this.clientSettings.apis.ecomm + '/product-charge',
      getSubscriptionProductId: this.clientSettings.apis.ecomm + '/subscription-product-id',
      createCheckoutSession: this.clientSettings.apis.ecomm + '/checkout-session',
      createPortalSession: this.clientSettings.apis.ecomm + '/portal-session',
      customer: this.clientSettings.apis.ecomm + '/customer',

      getFxRate: this.clientSettings.apis.ecomm + '/rate',
    };
  }

  setClient() {
    const client = this.clientSettings.client;
    this.logo = client === 'cps' ? 'assets/img/cps-logo.jpg' : this.logo;
    this.logoClass = client === 'cps' ? 'placeholder-logo' : this.logoClass;
    this.logoClass2 = client === 'cps' ? 'placeholder-logo-2' : this.logoClass2;
  }
}
