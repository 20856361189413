import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AppConfiguration } from '../../app.configuration';
import { NavigationService } from '../../services/navigation/navigation.service';
import { OrganizationService } from '../../services/organization/organization.service';
import { Organization } from '../../services/organization/organization.interface';
import { User } from '../../services/user/user.interface';
import { UserService } from '../../services/user/user.service';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Token } from 'src/app/services/user/token.interface';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {

  loginForm: FormGroup;

  errors: Array<string>;

  working = false;

  logo: string;

  logoClass: string;

  requirePaymentOnClaimCompany = false;

  constructor(
    private formBuilder: FormBuilder,
    private appConfiguration: AppConfiguration,
    private navigationService: NavigationService,
    private userService: UserService,
    private organizationSerivce: OrganizationService) {

    this.errors = [];

    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.logo = this.appConfiguration.logo;

    this.logoClass = this.appConfiguration.logoClass;

    this.requirePaymentOnClaimCompany =
      this.appConfiguration.clientSettings.requirePaymentOnClaimCompany;
  }

  ngOnInit() {
    this.userService.signOut();
  }

  signIn(): void {
    this.working = true;

    this.resetErrors();

    this.userService.signIn(
      this.loginForm.get('email').value,
      this.loginForm.get('password').value,
    ).subscribe(
      (data: Token | Array<string>) => {
        this.handleSignInResponse(data);
      }
    );
  }

  goToSignUp(): void {
    this.navigationService.go('/sign-up');
  }

  goToRequestPassword(): void {
    this.navigationService.go('/request-password');
  }

  private resetErrors() {
    this.errors = [];
  }

  private parseErrors(data) {
    if (data && data.length) {
      this.errors = data;
      return true;
    }
    return false;
  }

  private handleSignInResponse(data: Token | Array<string>) {
    this.working = false;

    if (this.parseErrors(data)) {
      return;
    }

    this.userService.getUser(data['userId']).subscribe((user: User | Array<string>) => {
      if (Array.isArray(user) ) {
        this.navigationService.go('/error');
      } else {
        this.navigationService.go('/select-organization');
      }
    });
  }

  private getDefaultOrganization(organizationId: number) {
    return this.organizationSerivce.getOrganization(organizationId);
  }

  private handleOrganizationResponse(organization: Organization) {
    if (organization.id) {
      if (organization.status === 'claimed' || !this.requirePaymentOnClaimCompany) {
        this.navigationService.go('/home');
      } else {
        this.navigationService.go('/pay/' + organization.id);
      }
    }
  }
}
