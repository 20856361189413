import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AppConfiguration } from '../app.configuration';
import { NavigationService } from '../services/navigation/navigation.service';
import { Token } from '../services/user/token.interface';
import { UserService } from '../services/user/user.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private appConfiguration: AppConfiguration,
    private userService: UserService,
    private navigationsService: NavigationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    try {
      const item = sessionStorage.getItem(this.appConfiguration.tokenKey);
      const token = JSON.parse(item);

      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: 'Bearer ' + token.accessToken
          }
        });
      }

      return next.handle(request);
    } catch(e) {
      this.navigationsService.go('/error');
    }
  }

  private refreshToken(accessToken: string): Observable<Token | Array<string>> {
    return this.userService.refreshToken(accessToken);
  }
}
