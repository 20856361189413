import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AppConfiguration } from '../../app.configuration';
import { NavigationService } from '../../services/navigation/navigation.service';
import { PasswordValidation } from '../../validators/password-match.validator';
import { PaymentService } from 'src/app/services/payments/payment.service';
import { StripeProductId } from 'src/app/services/ecomm/stripe-product-id.interface';
import { UserService } from '../../services/user/user.service';
import { User } from 'src/app/services/user/user.interface';
import { of } from 'rxjs';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  registrationForm: FormGroup;

  errors: Array<string>;

  working = false;

  logo: string;

  logoClass: string;

  termsAndConditionsLink: string;

  productId: StripeProductId;

  constructor(
    private appConfiguration: AppConfiguration,
    private formBuilder: FormBuilder,
    private navigationService: NavigationService,
    private route: ActivatedRoute,
    private userService: UserService,
    private paymentService: PaymentService) {

    this.errors = [];

    this.route.queryParams.subscribe(params => {
      this.resolveQueryParams(params);
    });

    this.registrationForm = this.formBuilder.group(
      {
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        userName: [''],
        email: ['', Validators.required],
        password: ['', Validators.required],
        passwordMatch: ['', Validators.required],
        terms: [false, Validators.required],
      },
      {
        validator: PasswordValidation.MatchPassword // your validation method
      }
    );

    this.registrationForm.valueChanges.subscribe(() => {
      if (this.errors.length > 0) {
        this.errors = [];
      }
    });

    this.logo = this.appConfiguration.logo;

    this.logoClass = this.appConfiguration.logoClass;

    this.termsAndConditionsLink = this.appConfiguration.termsAndConditionsLink;
  }

  ngOnInit() {
    this.userService.signOut();
  }

  register(): void {
    this.working = true;

    this.resetErrors();

    const sessionParams = {
      userName: this.registrationForm.get('email').value,
      email: this.registrationForm.get('email').value,
      firstName: this.registrationForm.get('firstName').value,
      lastName: this.registrationForm.get('lastName').value,
      password: this.registrationForm.get('password').value
    };

    this.userService.signUp(
      this.appConfiguration.clientSettings.client,
      sessionParams.userName,
      sessionParams.email,
      sessionParams.firstName,
      sessionParams.lastName,
      sessionParams.password).
      subscribe((data: User | Array<string>) => {
        this.working = false;

        if (!this.parseErrors(data)) {
          this.handleSignUpResponse(data);
        } else {
          return of([]);
        }
      });
  }

  goToSignIn(): void {
    this.navigationService.go('/sign-in');
  }

  private resolveQueryParams(params: any): void {
    /*
    const purchaseCode: string = params.purchaseCode;

    if (purchaseCode) {
      this.paymentService.validatePurchaseCode(purchaseCode).subscribe(
        (productId: StripeProductId) => {
          if (productId) {
            this.productId = productId;
            this.navigationService.clearQueryParams();
          } else {
            this.navigationService.go('/error');
          }
        }
      )
    } else {
      this.navigationService.go('/error');
    }
    */
  }

  private resetErrors() {
    this.errors = [];
  }

  private handleSignUpResponse(data: User | Array<string>) {
    this.navigationService.go('/choose-organization');
  }

  private parseErrors(data) {
    if (Array.isArray(data) && data && data.length) {
      this.errors = data;
      return true;
    }
    return false;
  }

}
