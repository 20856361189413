import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { AngularStripeService } from '@fireflysemantics/angular-stripe-service';

import { PaymentService } from '../../services/payments/payment.service';

import { AppConfiguration } from 'src/app/app.configuration';
import { Product } from '../../services/product/product.interface';

@Component({
  selector: 'app-stripe-card',
  templateUrl: './stripe-card.component.html',
  styleUrls: ['./stripe-card.component.css']
})
export class StripeCardComponent implements OnInit, AfterViewInit {

  @Input()
  name;

  @Input()
  email;

  @Input()
  amount;

  @Input()
  currency;

  @Input()
  organizationId?: number;

  @Input()
  product?: Product;

  @Input()
  showPayButtonOnValidForm ?= true;

  @Input()
  bypass = false;

  @Output()
  paymentReady: EventEmitter<any>;

  @Output()
  paymentBypassed: EventEmitter<boolean>;

  @Output()
  paymentFormCompleted: EventEmitter<any>;

  errors = [];

  working = false;

  paymentFormValid = false;

  private stripe: any;

  private elements: any;

  private card: any;

  // Custom styling can be passed to options when creating an Element.
  // (Note that this demo uses a wider set of styles than the guide below.)
  private style = {
    base: {
      color: '#32325d'
    }
  };

  constructor(
    private appConfiguration: AppConfiguration,
    private paymentService: PaymentService,
    private stripeService: AngularStripeService) {
    this.paymentReady = new EventEmitter<any>();
    this.paymentBypassed = new EventEmitter<boolean>();
    this.paymentFormCompleted = new EventEmitter<any>();
  }

  ngOnInit(): void {
    this.stripeService.setPublishableKey(this.appConfiguration.stripePublishableKey).
      then((stripe: any) => {
        this.buildCard(stripe);
      });
  }

  ngAfterViewInit() { }

  public pay(event?: any) {
    if (this.bypass) {
      this.paymentBypassed.emit(this.bypass);
    } else {
      this.working = true;
      this.errors = [];

      this.paymentService.
        createProductPayment(
          this.product.id,
          'stripe',
          this.amount,
          this.currency,
          this.email,
          this.name,
          this.organizationId).
        subscribe(
          (results: any) => {
            if (Array.isArray(results)) {
              this.errors = this.errors.concat(results);
            } else {
              const name = this.name;
              const clientSecret = results.purchaseRecord.intent.client_secret;

              this.stripe.handleCardPayment(
                clientSecret,
                this.card,
                {
                  payment_method_data: {
                    billing_details: { name }
                  }
                }

              ).then((result) => {
                this.working = false;

                if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
                  result['productChargeId'] = results.id;
                  this.paymentReady.emit(result);
                } else if (result.error) {
                  this.errors.push(result.error['message']);
                }

              });
            }


          }
        );
      }
  }

  private buildCard(stripe: any) {
    this.stripe = stripe;
    this.elements = this.stripe.elements();

    // Create an instance of the card Element.
    this.card = this.elements.create('card', {
      hidePostalCode: true,
      style: this.style
    });

    // Add an instance of the card Element into the `card-element` <div>.
    this.card.mount('#card-element');

    this.card.on('change', (event) => {
      this.errors = [];

      if (event.complete) {
        this.paymentFormValid = true;

        if (!this.showPayButtonOnValidForm) {
          this.paymentFormCompleted.emit();
        }

      } else {
        this.working = false;
        this.paymentFormValid = false;
      }

      if (event.error) {
        this.errors.push(event.error.message);
      }

    });

  }

}
