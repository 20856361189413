import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Product } from '../../services/product/product.interface';

import { ProductService } from '../../services/product/product.service';
import { ExchangeRate } from '../../services/product/exchange-rate.interface';

@Component({
  selector: 'app-product-selector',
  templateUrl: './product-selector.component.html',
  styleUrls: ['./product-selector.component.css']
})
export class ProductSelectorComponent implements OnInit {

  @Input()
  public showDefault ?= false;

  @Input()
  public products: Array<Product>;

  @Input()
  public currencies: Array<string>;

  @Input()
  public defaultCurrency?: string;

  @Output()
  public productSelected: EventEmitter<Product>;

  @Output()
  public currencySelected: EventEmitter<{ price: number, currency: string }>;

  public productsForm: FormGroup;

  public selectedCurrency: string;

  public selectedProductIndex: number;

  public selectedCurrencyIndex: number;

  public currentPrice: string;

  constructor(
    private formBuilder: FormBuilder,
    private productService: ProductService) {

    this.selectedProductIndex = 0;
    this.selectedCurrencyIndex = 0;

    this.productSelected = new EventEmitter<Product>();
    this.currencySelected = new EventEmitter<{ price: number, currency: string }>();

    this.productsForm = this.formBuilder.group({
      product: ['', Validators.required],
      currency: ['', Validators.required]
    });

  }

  ngOnInit(): void {
    const product: Product = this.products[this.selectedProductIndex];

    this.productsForm.patchValue({
      product: product.description,
      currency: product.baseCurrency
    });

    this.currentPrice = product.price.toFixed(2);
    this.selectedCurrency = product.baseCurrency;

    this.setDefaultCurrency();

    this.selectProduct(this.selectedProductIndex);
    this.selectCurrency(this.selectedCurrencyIndex);

  }

  selectProduct(selectedProductIndex: number) {
    this.selectedProductIndex = selectedProductIndex;
    this.selectCurrency(this.selectedCurrencyIndex);

    this.productSelected.emit(this.products[selectedProductIndex]);
  }

  selectCurrency(selectedCurrencyIndex: number) {
    const product: Product = this.products[this.selectedProductIndex];

    this.selectedCurrencyIndex = selectedCurrencyIndex;
    this.selectedCurrency = this.currencies[this.selectedCurrencyIndex];

    this.productService.getFxRate(
      product.baseCurrency,
      this.selectedCurrency,
      (new Date()).toISOString().substring(0, 10)
    ).subscribe((exchangeRate: ExchangeRate) => {
      const price = (product.price * exchangeRate.rate);

      this.currentPrice = price.toFixed(2);
      this.currencySelected.emit({ price, currency: this.selectedCurrency });
    });
  }

  private setDefaultCurrency() {
    if (this.defaultCurrency) {
      this.currencies.forEach((currency: string, index: number) => {
        if (currency === this.defaultCurrency) {
          this.selectedCurrencyIndex = index;
        }
      });
    }
  }

}
