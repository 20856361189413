import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AppConfiguration } from '../../app.configuration';
import { Country } from '../../services/organization/country.interface';
import { Industry } from '../../services/organization/industry.interface';
import { NavigationService } from '../../services/navigation/navigation.service';
import { Organization } from '../../services/organization/organization.interface';
import { OrganizationService } from '../../services/organization/organization.service';
import { STATUS_CLAIMED } from '../../components/add-organization/add-organization.component';
import { User } from '../../services/user/user.interface';
import { UserService } from '../../services/user/user.service';
import { PaymentService } from 'src/app/services/payments/payment.service';
import { StripeCheckoutSession } from 'src/app/services/payments/stripe-checkout-session.interface';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-choose-organization',
  templateUrl: './choose-organization.component.html',
  styleUrls: ['./choose-organization.component.css']
})
export class ChooseOrganizationComponent implements OnInit {

  requirePaymentOnClaimCompany = false;

  user: User;

  errors: Array<string>;

  succeeded: boolean;

  working = false;

  logo: string;

  logoClass: string;

  countries: Array<Country>;

  industries: Array<Industry>;

  private client: string;

  private productId: number;

  constructor(
    private appConfiguration: AppConfiguration,
    @Inject(DOCUMENT) private document: Document,
    private navigationService: NavigationService,
    private organizationService: OrganizationService,
    private paymentService: PaymentService,
    private route: ActivatedRoute,
    private userService: UserService) {

    this.errors = [];
    this.succeeded = false;

    this.logo = this.appConfiguration.logo;

    this.logoClass = this.appConfiguration.logoClass;

    this.requirePaymentOnClaimCompany =
      this.appConfiguration.clientSettings.requirePaymentOnClaimCompany;

    this.client =
      this.appConfiguration.clientSettings.client;

    this.productId = parseInt(sessionStorage.getItem('product_id'));

    this.route.data.subscribe(
      (data: {
        user: User,
        organization: Organization,
        countries: Array<Country>,
        industries: Array<Industry>
      }) => {
        this.user = data.user;
        this.countries = data.countries;
        this.industries = data.industries;
      });

  }

  ngOnInit(): void {
    this.route.data.subscribe(
      (data: { user: User }) => {
        if (!data.user) {
          this.goToSignIn();
        }
        this.user = data.user;
      });
  }

  goToSignIn(): void {
    this.navigationService.go('/sign-in');
  }

  selectOrganization(organizationInfo: any): void {
    const organization: Organization = organizationInfo['organization'];
    this.organizationService.storeSelectedOrganization(organization.id);
    this.navigationService.go('/pricing');
  }

  private resolveUnclaimedOrganization(organization) {
    if (this.requirePaymentOnClaimCompany) {
      this.navigationService.go('/pricing?organizationId=' + organization.id);
    } else {
      this.organizationService.
        updateSelectedOrganization(this.user.id, organization.id).
        subscribe((user: User) => {
          this.organizationService.
            updateOrganizationStatus(organization.id, STATUS_CLAIMED).
            subscribe(() => {
              this.userService.updateUser(user);
              this.navigationService.go('/home');
            });
        });
    }
  }
}
