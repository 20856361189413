import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppConfiguration } from '../../app.configuration';
import { NavigationService } from '../../services/navigation/navigation.service';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-confirm-account',
  templateUrl: './confirm-account.component.html',
  styleUrls: ['./confirm-account.component.css']
})
export class ConfirmAccountComponent implements OnInit {

  public logo: string;

  public logoClass: string;

  public errors = [];

  private confimrationCode: string;

  constructor(
    private route: ActivatedRoute,
    private appConfiguration: AppConfiguration,
    private navigationService: NavigationService,
    private userService: UserService) {

    this.logo = this.appConfiguration.logo;

    this.logoClass = this.appConfiguration.logoClass;

    this.route.paramMap.subscribe(
      (params: any) => {
        this.userService.confirmUser(params.params.code).subscribe((results: Array<string>) => {
          if (results.length > 0) {
            this.navigationService.go('/error');
          }
        });
      });
  }

  ngOnInit(): void {
    this.userService.signOut();
  }

  goToSignIn(): void {
    this.navigationService.go('/sign-in');
  }
}
