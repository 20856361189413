import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve} from '@angular/router';
import { Observable, of } from 'rxjs';

import { Country } from './country.interface';
import { OrganizationService } from './organization.service';

@Injectable()
export class CountryResolverService implements Resolve<Array<Country> | Array<string>> {

  constructor(private organizationService: OrganizationService) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Array<Country> | Array<string>> {
    return this.organizationService.getCountries();
  }
}

