import { payableCurrencySymbols } from "./currency-symbols";

export const RESOLVE_SUPPLIERS_GRID_DATA = {
    endpoint: '/organization/%organizationId%/report',
    confirmEvent: 'confirm-resolve-transaction-supplier',
    resolveEvent: 'resolve-transaction-supplier',
    columnDefinitions: [
        {
            title: '',
            data: 'supplierId',
            orderable: false,
            searchable: false,
            visible: false
        },
        {
            title: '',
            data: 'transactionId',
            orderable: false,
            searchable: false,
            visible: false
        },
        {
            title: 'Confirmed?',
            data: 'ledgerNameConfirmed',
            orderable: true,
            searchable: false,
            render: (data, type, row, meta) => {
                const officialSupplierValidForConfirmation =
                    '<input id="cell-event#confirm-resolve-transaction-supplier#' + meta['row'] + '#' + meta['col'] + '" class="checkbox rounded" ' +
                    (data ? 'checked=\"checked\"' : '') + ' type="checkbox"><span></span>';

                return '<label>' + officialSupplierValidForConfirmation + '</label>';
            }
        },
        {
            title: 'Supplier (From Accounting)',
            data: 'ledgerName',
            orderable: true,
            searchable: false
        },
        {
            title: 'Legal Entity Match',
            data: 'supplierName',
            orderable: true,
            searchable: false,
            render: (data, type, row, meta) => {
                let content = '<td><div><span>';
                if (data) {
                    content += '<span style="display: inline-block;"><div data-toggle="popover" data-title="';
                    content += (data !== null && data !== 'tbd') ? data : '';
                    content += '" data-content="<div>';
                    // content += (row['address']['street'] && row['address']['street'] !== 'tbd') ? row['address']['street'] : '';
                    content += '</div><div>';
                    content += (row['address']['city'] && row['address']['city'] !== 'tbd') ? row['address']['city'] : '';
                    content += '</div><div>';
                    content += (row['address']['country'] && row['address']['country'] !== 'tbd') ? row['address']['country'] : '';
                    content += '</div><div>';
                    content += row['industryName'];
                    content += '</div>" data-placement="right" data-html="true" data-trigger="hover">';
                    content += '<a id="cell-event#resolve-transaction-supplier#' + meta['row'] + '#' +
                        meta['col'] + '" href="javascript:void(0);">' + data + '</a>';
                    content += '</div></span>';
                } else {
                    content += '<i id="cell-event#resolve-transaction-supplier#' + meta['row'] + '#' + meta['col'] +
                        '" class="fa fa-question-circle" style="font-size:18px; cursor:pointer; color:#3498DB;"></i>';
                }
                content += '</span></div></td>';

                return content;
            }
        },
        {
            title: 'Industry',
            data: 'industryName',
            orderable: true,
            searchable: false,
            render: (data, type, row, meta) => {
                return data ? data : 'Other Community, Social and Personal Services';
            }
        },
        {
            title: 'Total Spent',
            data: 'amount',
            orderable: true,
            searchable: false,
            render: (data, type, row, meta) => {
                const currencySymbol = payableCurrencySymbols[row.currency];
                var hasAccess = meta.settings.oInit.scopes.indexOf('view-purchase-details') != -1;
                return !hasAccess ? '-' : currencySymbol + ' ' + parseFloat(data).toFixed(2);
            }
        },
        {
            title: 'Currency',
            data: 'currency',
            orderable: false,
            searchable: false,
            visible: false,
            render: (data, type, row, meta) => {
                var hasAccess = meta.settings.oInit.scopes.indexOf('view-purchase-details') != -1;
                return !hasAccess ? '-' : data;
            }
        }
    ],
    dataSrc: {
        recordsTotalAlias: 'totalSuppliers',
        recordsFilteredAlias: 'filteredSuppliers',
        recordsAlias: 'suppliers'
    },
    queryDefinition: {
        type: 'supplierDetails'
    },
    defaultSortColumn: 'amount',
    defaultSortOrder: 'desc',
    sortCriteriaMap: {
        supplierName: 'supplierName',
        ledgerName: 'ledgerName',
        industryName: 'industryName',
        amount: 'amount'
    }
};
