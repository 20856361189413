import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Resolve
} from '@angular/router';
import { Observable, of } from 'rxjs';

import { User } from './user.interface';
import { UserService } from './user.service';

@Injectable()
export class UserResolverService implements Resolve<User | Array<string>> {

  constructor(private userService: UserService) { }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User | Array<string>> {
    return this.userService.getUser();
  }

}
