import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PayComponent } from './pay.component';

import { CurrencyResolverService } from '../../services/product/currency-resolver.service';
import { ProductResolverService } from '../../services/product/product-resolver.service';
import { UserResolverService } from '../../services/user/user-resolver.service';

const routes: Routes = [
  {
    path: '',
    resolve: {
      user: UserResolverService,
      products: ProductResolverService,
      currencies: CurrencyResolverService
    },
    data: { payableCurrencies: ['GBP', 'EUR', 'USD'] },
    component: PayComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PayRoutingModule { }
