import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ConfirmAccountComponent } from './confirm-account.component';

@NgModule({
  declarations: [
    ConfirmAccountComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ConfirmAccountComponent
  ],
  providers: []
})
export class ConfirmAccountModule { }


