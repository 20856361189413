import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { GlobalDateRangeModule } from '../../components/global-date-range/global-date-range.module';
import { HeaderModule } from '../../components/header/header.module';
import { HomeComponent } from './home.component';
import { LoadingSpinnerModule } from '../../components/loading-spinner/loading-spinner..module';
import { NumberCycleModule } from '../../components/number-cycle/number-cycle.module';
import { PercentageSpentResolveModule } from '../../components/percentage-spent-resolve/percentage-spent-resolve.module';
import { SideBarModule } from '../../components/side-bar/side-bar..module';
import { TreeSideBarModule } from '../../components/tree-side-bar/tree-side-bar.module';

@NgModule({
  declarations: [
    HomeComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SideBarModule,
    TreeSideBarModule,
    HeaderModule,
    LoadingSpinnerModule,
    GlobalDateRangeModule,
    NumberCycleModule,
    PercentageSpentResolveModule
  ],
  exports: [
    HomeComponent
  ],
  providers: []
})
export class HomeModule {}
