import { Component, OnInit } from '@angular/core';
import { of } from 'rxjs';

import { ActivatedRoute } from '@angular/router';
import { Customer } from 'src/app/services/payments/customer.interface';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { PaymentService } from 'src/app/services/payments/payment.service';
import { OrganizationService } from 'src/app/services/organization/organization.service';
import { STATUS_CLAIMED } from '../../components/add-organization/add-organization.component';
import { User } from 'src/app/services/user/user.interface';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.css']
})
export class RedirectComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private organizationService: OrganizationService,
    private paymentService: PaymentService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.resolveQueryParams(params);
    });
  }

  private resolveQueryParams(params: any): void {
    const sessionId: string = params.session_id;
    const organizationId: number = params.organizationId;

    if (sessionId && organizationId) {
      const user = this.userService.getUserFromSession();

      this.paymentService.createCustomer(sessionId, organizationId).subscribe(
        (customer: Customer) => {
          this.claimOrganizaton(user.id, organizationId);
        }
      )
    } else {
      this.navigationService.go('/error');
    }
  }

  private claimOrganizaton(userId, organizationId) {
    this.organizationService.
      updateSelectedOrganization(userId, organizationId).
      subscribe((user: User) => {
        if (Array.isArray(user)) {
          this.navigationService.go('/home');
        } else {
          this.organizationService.
            updateOrganizationStatus(organizationId, STATUS_CLAIMED).
            subscribe(() => {
              this.userService.updateUser(user);
              this.navigationService.go('/home');
            });
        }
      });
  }
}
