import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppConfiguration } from '../../app.configuration';
import { AngularStripeService } from '@fireflysemantics/angular-stripe-service';
import { Customer } from './customer.interface';
import { StripeProductId } from '../ecomm/stripe-product-id.interface';
import { StripeCheckoutSession } from './stripe-checkout-session.interface';
import { PortalSession } from './portal-session.interface';

export const PROVIDER_STRIPE = 'stripe';
export const PROVIDER_PAYPAL = 'paypal';

@Injectable()
export class PaymentService {

  constructor(
    private appConfiguration: AppConfiguration,
    private httpClient: HttpClient) {}

  createPortalSession(organizationId: number, returnUrl: string) {
      return this.httpClient.post<PortalSession>(
          this.appConfiguration.endpoints['createPortalSession'],
          {
            organizationId,
            returnUrl
          },
          {
            observe: 'body',
            responseType: 'json'
          }
        ).pipe(
          map((data: any) => {
            return data['data'];
          }),
          map((portalSession: PortalSession) => {
            return portalSession;
          }),
          catchError(this.handleError)
        );
    }

  createCheckoutSession(
    organizationId: number,
    productId: number,
    checkoutSuccessUrl: string,
    checkoutFailedUrl: string) {
    return this.httpClient.post<StripeCheckoutSession>(
        this.appConfiguration.endpoints['createCheckoutSession'],
        {
          organizationId,
          productId,
          checkoutSuccessUrl,
          checkoutFailedUrl
        },
        {
          observe: 'body',
          responseType: 'json'
        }
      ).pipe(
        map((data: any) => {
          return data['data'];
        }),
        map((stripeCheckoutSession: StripeCheckoutSession) => {
          return stripeCheckoutSession;
        }),
        catchError(this.handleError)
      );
  }

  createProductPayment(
    productId: number,
    paymentProvider: string,
    amount: number,
    currency: string,
    email: string,
    description: string,
    organizationId: number): Observable<any | Array<string>> {
    return this.httpClient.post<any>(
      this.appConfiguration.endpoints['initiatePayment'],
      {
        productId,
        paymentProvider,
        amount,
        currency,
        email,
        description,
        organizationId
      },
      {
        observe: 'body',
        responseType: 'json'
      }
    ).pipe(
      map((data: object) => {
        return data['data'];
      }),
      map((result: any) => {
        return result;
      }),
      catchError(this.handleError)
    );
  }

  updateProductPayment(
    productChargeId: number,
    productChargeType: string,
    paymentProvider: string): Observable<any | Array<string>> {
    return this.httpClient.put<any>(
      this.appConfiguration.endpoints['updatePayment'],
      {
        productChargeId,
        productChargeType,
        paymentProvider
      },
      {
        observe: 'body',
        responseType: 'json'
      }
    ).pipe(
      map((data: object) => {
        return data['data'];
      }),
      map((result: any) => {
        return result;
      }),
      catchError(this.handleError)
    );
  }

  validatePurchaseCode(purchaseCode: string): Observable<StripeProductId | Array<string>> {
    const url = this.appConfiguration.endpoints['getSubscriptionProductId'] + '?purchaseCode=' + purchaseCode;

    return this.httpClient.get<StripeProductId>(
      url,
      {
        observe: 'body',
        responseType: 'json'
      }
    ).pipe(
      map((data: any) => {
        return data['data'];
      }),
      map((productId: StripeProductId) => {
        return productId;
      }),
      catchError(this.handleError)
    );
  }

  createCustomer(sessionId: string, organizationId: number) {
    const url = this.appConfiguration.endpoints['customer'];

    return this.httpClient.post<Customer>(
      url,
      {
        sessionId,
        organizationId
      },
      {
        observe: 'body',
        responseType: 'json'
      }
    ).pipe(
      map((data: any) => {
        return data['data'];
      }),
      map((customer: Customer) => {
        return customer;
      }),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse): Observable<Array<string>> {
    return of((error.error.errors));
  }
}

