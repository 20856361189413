import { Component, Input, OnInit } from '@angular/core';
import { Certification } from '../../services/organization/certification.interface';
import { EarnedBadges } from '../../services/organization/earned-badges.interface';

export const CERTIFICATION_AWARE = 'aware';
export const CERTIFICATION_EFFICIENT = 'efficient';
export const CERTIFICATION_OFFSETTER = 'offsetter';
export const CERTIFICATION_ADVOCATE = 'advocate';

@Component({
  selector: 'app-certifications-panel',
  templateUrl: './certifications-panel.component.html',
  styleUrls: ['./certifications-panel.component.css']
})
export class CertificationsPanelComponent implements OnInit {

  @Input()
  earnedBadges: EarnedBadges;

  constructor() {}

  ngOnInit(): void {
    /*
    this.certifications.forEach((certification: Certification) => {
      switch (certification.type) {
        case CERTIFICATION_AWARE:
          if (!this.hasAware) {
            this.hasAware = this.hasCert(certification);
          }
          break;
        case CERTIFICATION_EFFICIENT:
          if (!this.hasEfficient) {
            this.hasEfficient = this.hasCert(certification);
          }
          break;
        case CERTIFICATION_OFFSETTER:
          if (!this.hasOffsetter) {
            this.hasOffsetter = this.hasCert(certification);
          }
          break;
        case CERTIFICATION_ADVOCATE:
          if (!this.hasAdvocate) {
            this.hasAdvocate = this.hasCert(certification);
          }
          break;
        default:
          break;
      }
    });
    */

  }

  hasCert(certification: Certification): boolean {
    const today = new Date();
    const expires = new Date(certification.expires);

    if (today <= expires) {
      return true;
    }

    return false;
  }

}
