import {
  Component,
  OnInit
} from '@angular/core';

import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

import { AppConfiguration, CLIENT_INSIGHT } from '../../app.configuration';

import { EarnedBadges } from '../../services/organization/earned-badges.interface';
import { ExchangeRate } from '../../services/product/exchange-rate.interface';
import { Feature } from '../../components/side-bar/feature.interface';
import { NavigationService } from '../../services/navigation/navigation.service';
import { Organization } from '../../services/organization/organization.interface';
import { OrganizationService } from '../../services/organization/organization.service';
import { ProductService } from '../../services/product/product.service';
import { User } from '../../services/user/user.interface';
import { UserService } from '../../services/user/user.service';
import { switchMap } from 'rxjs/operators';
import { ScriptService } from 'src/app/services/script/script.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  user: User;

  organization: Organization;

  client: string;

  logo: string;

  logoClass: string;

  features: Array<Feature>;

  currencies: Array<string>;

  selected: string;

  reportingYearIndex = 0;

  reportingYear = [];

  showGlobalDateSelector = false;

  showReportingYearToggle = false;

  showSupplierResolution = false;

  startDate: Date;

  endDate: Date;

  reportingYears: Array<any> = [];

  spend: number;

  showCerts = false;

  earnedBadges: EarnedBadges;

  private globalDateSelectorEnabled = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private appConfiguration: AppConfiguration,
    private navigationService: NavigationService,
    private userService: UserService,
    private organizationService: OrganizationService,
    private productService: ProductService,
    private scriptService: ScriptService) {

    this.route.data.subscribe(
      (data: {
        home: { user: User, organization: Organization },
        currencies: Array<string>
      }) => {

        if (!data || !data.home) {
          return;
        }

        this.user = data.home.user;

        this.client = this.appConfiguration.clientSettings.client;

        this.logo = this.appConfiguration.logo;

        this.logoClass = this.appConfiguration.logoClass2;

        this.features = this.appConfiguration.features;

        this.selected = this.appConfiguration.features[0].route;

        this.globalDateSelectorEnabled = this.appConfiguration.enableDashboardDateSelector;

        if (data.currencies) {
          this.currencies = data.currencies;
        }

        if (!this.user ||
          !this.user.preferences ||
          !this.user.preferences.selectedOrganizationId) {
          this.navigationService.go('/');
        }

        this.organizationService.
          getOrganization(this.user.preferences.selectedOrganizationId).
          subscribe((organization: Organization) => {
            this.organization = organization;

            this.mapEarnedBadges();

            this.startDate = new Date(organization.reportsGlobalStartDate);
            this.endDate = new Date(organization.reportsGlobalEndDate);
            this.spend = organization.supplierResolutionProgress.percentage;

            this.showCerts = this.resolveShowCerts();

          });

        // TODO: Need a more robust to enable/disable the global date range
        this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            if (this.globalDateSelectorEnabled) {
              const uri = event.url.split('/')[2];

              this.showReportingYearToggle = false;
              this.showGlobalDateSelector = false;
              this.showSupplierResolution = false;

              switch (uri) {
                case 'resolve-suppliers':
                  this.showReportingYearToggle = true;
                  this.showGlobalDateSelector = true;
                  this.showSupplierResolution = true;
                  break;
                case 'dashboard':
                case 'supplier-summary':
                case 'purchase-details':
                case 'products':
                  this.showGlobalDateSelector = true;
                  break;
                default:
                  break;
              }
            }

          }
        });

      });

  }

  ngOnInit(): void {}

  signOut(): void {
    this.organizationService.reset();
    this.userService.signOut();
    this.navigationService.go('/');
  }

  changeSelection(feature: Feature): void {
    this.navigationService.go(feature.route);
  }

  changeCurrency(currency: string): void {
    this.updateReportingCurrency(currency);
  }

  changeDateInterval(dateInterval: { startDate: Date, endDate: Date }): void {
    this.organizationService.updateGlobalReportInterval(
      this.organization.id,
      dateInterval.startDate.toISOString().split('T')[0],
      dateInterval.endDate.toISOString().split('T')[0]
    ).subscribe(() => {

    });
  }

  updateReportingYear(reportingYear: string): void {
    this.organizationService.updateReportingYear(
      this.organization.id,
      reportingYear
    ).subscribe((organization: Organization) => { });
  }

  private updateReportingCurrency(quoted: string) {
    const today = new Date();
    const base = this.appConfiguration.baseMeasuremntCurrency;

    this.productService.getFxRate(
      base,
      quoted,
      (new Date()).toISOString().substring(0, 10)
    ).pipe(
      switchMap((exchangeRate: ExchangeRate) => {
        return this.organizationService.
          updateReportingCurrency(this.organization.id, exchangeRate.quoted)
      })
    ).subscribe((organization: Organization) => {
      this.organization.reportingCurrency = quoted;
    });
  }

  private buildReportingYears() {
    let reportingYear = this.organization.minReportingYear;

    while (reportingYear <= this.organization.maxReportingYear) {
      this.reportingYears.push(reportingYear);

      if (reportingYear === this.organization.activeReportingYear) {

      }

      ++reportingYear;
    }
  }

  private resolveShowCerts() {
    if (this.client === CLIENT_INSIGHT) {
      return true;
    }
    return false;
  }

  private mapEarnedBadges() {
    this.earnedBadges = {
      aware: false,
      efficient: false,
      offsetter: false,
      advocate: false
    };

    this.organization.earnedBadges.forEach(badge => {
      this.earnedBadges[badge] = true;
    });

    if (this.organization.certifications) {
      this.organization.certifications.forEach(certification => {
        this.earnedBadges[certification.type] = true;
      });
    }
  }
}
