import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Resolve
} from '@angular/router';
import { Observable, of } from 'rxjs';

import { map } from 'rxjs/operators';
import { Organization } from '../organization/organization.interface';

import { User } from '../user/user.interface';
import { UserService } from '../user/user.service';


@Injectable()
export class HomeDataResolverService implements Resolve<{ user: User, organization: Organization }> {

  constructor(private userService: UserService) { }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<{ user: User, organization: Organization }> {

    const userFromSession: User = this.userService.getUserFromSession();
    if (userFromSession) {
      return of({ user: userFromSession, organization: undefined });
    }

    return this.userService.getUser().pipe(
      map((user: User) => {
        return {
          user,
          organization: undefined  // TODO: Need to find a way to pull both streams in simultaneously
        };
      }));
  }
}
