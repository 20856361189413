import { payableCurrencySymbols } from "./currency-symbols";

export const TRANSACTIONS_SUMMARY_GRID_DATA = {
    endpoint: '/organization/%organizationId%/report',
    columnDefinitions: [
        {
            title: '',
            data: 'transactionId',
            orderable: false,
            searchable: false,
            visible: false
        },
        {
            title: 'Supplier',
            data: 'supplierName',
            orderable: true,
            searchable: false,
            render: (data, type, row, meta) => {
                let content = '<td><div><span>';
                content += '<span style="display: inline-block;"><div data-toggle="popover" data-title="';
                content += (data !== null && data !== 'tbd') ? data : '';
                content += '" data-content="<div>';
                content += (row['address']['city'] && row['address']['city'] !== 'tbd') ? row['address']['city'] : '';
                content += '</div><div>';
                content += (row['address']['country'] && row['address']['country'] !== 'tbd') ? row['address']['country'] : '';
                content += '</div><div>';
                content += row['industryName'];
                content += '</div>" data-placement="right" data-html="true" data-trigger="hover">';
                content += data;
                content += '</div></span>';

                return content;
            }
        },
        {
            title: 'Date',
            data: 'date',
            orderable: true,
            searchable: false,
            render: (data, type, row, meta) => {
                return (new Date(data)).toISOString().substr(0, 10);
            }
        },
        {
            title: 'Amount',
            data: 'amount',
            orderable: true,
            searchable: false,
            render: (data, type, row, meta) => {
                const currencySymbol = payableCurrencySymbols[row.currency];
                return currencySymbol + ' ' + parseFloat(data).toFixed(2);
            }
        },
        {
            title: 'Currency',
            data: 'currency',
            orderable: false,
            searchable: false,
            visible: false
        },
        {
            title: 'Industry',
            data: 'industryName',
            orderable: true,
            searchable: false,
            render: (data, type, row, meta) => {
                return data ? data : 'Other Community, Social and Personal Services';
            }
        },
        {
            title: 'Total tCO2e',
            data: 'allScopesTotal',
            orderable: true,
            searchable: false,
            render: (data, type, row, meta) => {
                return parseFloat(data).toFixed(4);
            }
        },
        {
            title: 'Scope 1 tCO2e',
            data: 'scope1Total',
            orderable: true,
            searchable: false,
            render: (data, type, row, meta) => {
                return parseFloat(data).toFixed(4);
            }
        },
        {
            title: 'Scope 2 tCO2e',
            data: 'scope2Total',
            orderable: true,
            searchable: false,
            render: (data, type, row, meta) => {
                return parseFloat(data).toFixed(4);
            }
        },
        {
            title: 'Scope 3 tCO2e',
            data: 'scope3Total',
            orderable: true,
            searchable: false,
            render: (data, type, row, meta) => {
                return parseFloat(data).toFixed(4);
            }
        },
        {
            title: 'Data Source',
            data: 'dataSource',
            orderable: false,
            searchable: false,
            render: (data, type, row, meta) => {
                let control = '<td>';
                control += '<div>';
                control += '<a href="' + data + '" target="_blank">' + data + '</a>';
                control += '</div>';
                control += '</td>';
                return control;
            }
        }
    ],
    dataSrc: {
        recordsTotalAlias: 'totalPurchases',
        recordsFilteredAlias: 'filteredPurchases',
        recordsAlias: 'purchases'
    },
    queryDefinition: {
        type: 'purchaseDetails'
    },
    defaultSortColumn: 'date',
    defaultSortOrder: 'desc',
    sortCriteriaMap: {
        supplierName: 'supplierName',
        ledgerName: 'ledgerName',
        industryName: 'industryName',
        allScopesTotal: 'allScopesTotal',
        scope1Total: 'scope1Total',
        scope2Total: 'scope2Total',
        scope3Total: 'scope3Total',
        date: 'date'
    }
};

export const PRODUCTS_SUMMARY_GRID_DATA = {
    endpoint: '/organization/%organizationId%/report',
    columnDefinitions: [
        {
            title: '',
            data: 'transactionId',
            orderable: false,
            searchable: false,
            visible: false
        },
        {
            title: 'Supplier',
            data: 'supplierName',
            orderable: true,
            searchable: false,
            render: (data, type, row, meta) => {
                let content = '<td><div><span>';
                content += '<span style="display: inline-block;"><div data-toggle="popover" data-title="';
                content += (data !== null && data !== 'tbd') ? data : '';
                content += '" data-content="<div>';
                content += (row['address']['city'] && row['address']['city'] !== 'tbd') ? row['address']['city'] : '';
                content += '</div><div>';
                content += (row['address']['country'] && row['address']['country'] !== 'tbd') ? row['address']['country'] : '';
                content += '</div><div>';
                content += row['industryName'];
                content += '</div>" data-placement="right" data-html="true" data-trigger="hover">';
                content += data;
                content += '</div></span>';

                return content;
            }
        },
        {
            title: 'Date',
            data: 'date',
            orderable: true,
            searchable: false,
            render: (data, type, row, meta) => {
                return (new Date(data)).toISOString().substr(0, 10);
            }
        },
        {
            title: 'Amount',
            data: 'amount',
            orderable: true,
            searchable: false,
            render: (data, type, row, meta) => {
              const currencySymbol = payableCurrencySymbols[row.currency];
              return currencySymbol + ' ' + parseFloat(data).toFixed(2);
            }
        },
        {
            title: 'Currency',
            data: 'currency',
            orderable: false,
            searchable: false,
            visible: false
        },
        {
            title: 'Product',
            data: 'productName',
            orderable: false,
            searchable: false
        },
        {
            title: 'Consumption',
            data: 'productAmount',
            orderable: false,
            searchable: false,
            render: (data, type, row, meta) => {
              let content = '<span id=';
              content += 'product-consumption#' + meta['row'] + '#' + meta['col'] + '>'
              content += parseFloat(data).toFixed(2) + '</span>';
              return content;
            }
        },
        {
            title: 'Unit',
            data: 'productAmountUnit',
            orderable: false,
            searchable: false
        },
        {
            title: 'Price',
            data: 'productPrice',
            orderable: false,
            searchable: false,
            render: (data, type, row, meta) => {
              let content = '<span id=';
              content += 'product-price#' + meta['row'] + '#' + meta['col'] + '>'
              content += parseFloat(data).toFixed(2) + '</span>';
              return content;
            }
        },
        {
            title: 'Price Unit',
            data: 'productPriceUnit',
            orderable: false,
            searchable: false
        },
        {
            title: 'Data Source',
            data: 'dataSource',
            orderable: false,
            searchable: false,
            render: (data, type, row, meta) => {
                let control = '<td>';
                control += '<div>';
                control += '<a href="' + data + '" target="_blank">' + data + '</a>';
                control += '</div>';
                control += '</td>';
                return control;
            }
        },
        {
            title: 'Industry',
            data: 'industryName',
            orderable: true,
            searchable: false,
            render: (data, type, row, meta) => {
                return data ? data : 'Other Community, Social and Personal Services';
            }
        },
        {
            title: 'Total tCO2e',
            data: 'allScopesTotal',
            orderable: true,
            searchable: false,
            render: (data, type, row, meta) => {
                return parseFloat(data).toFixed(4);
            }
        },
        {
            title: 'Scope 1 tCO2e',
            data: 'scope1Total',
            orderable: true,
            searchable: false,
            render: (data, type, row, meta) => {
                return parseFloat(data).toFixed(4);
            }
        },
        {
            title: 'Scope 2 tCO2e',
            data: 'scope2Total',
            orderable: true,
            searchable: false,
            render: (data, type, row, meta) => {
                return parseFloat(data).toFixed(4);
            }
        },
        {
            title: 'Scope 3 tCO2e',
            data: 'scope3Total',
            orderable: true,
            searchable: false,
            render: (data, type, row, meta) => {
                return parseFloat(data).toFixed(4);
            }
        },
        {
          title: 'Edit?',
          searchable: false,
          orderable: false,
          render: (data, type, row, meta) => {
            let content = '<span><i id="cell-event#product-edit#' + meta['row'] + '#' + meta['col'] +
              '" class="fa fa-edit" style="font-size:18px; cursor:pointer; color:#3498DB; padding:0 4px;" title="Edit"></i></span>';
            content += '<span><i id="cell-event#product-confirm#' + meta['row'] + '#' + meta['col'] +
            '" class="fa fa-check" style="display: none; font-size:18px; cursor:pointer; color:#3498DB; padding:0 4px;" title="Confirm"></i></span>';
            content += '<span><i id="cell-event#product-clear#' + meta['row'] + '#' + meta['col'] +
            '" class="fa fa-remove" style="font-size:18px; cursor:pointer; color:#3498DB; padding:0 4px;" title="Restore guess"></i></span>';
            return content;
          }
      }
    ],
    dataSrc: {
        recordsTotalAlias: 'totalProducts',
        recordsFilteredAlias: 'filteredProducts',
        recordsAlias: 'products'
    },
    queryDefinition: {
        type: 'productDetails'
    },
    defaultSortColumn: 'date',
    defaultSortOrder: 'desc',
    sortCriteriaMap: {
        supplierName: 'supplierName',
        industryName: 'industryName',
        amount: 'amount',
        date: 'date',
        productName: 'productName',
        productAmount: 'productAmount',
        productAmountUnit: 'productAmountUnit',
        productPrice: 'productPrice',
        productPriceUnit: 'productPriceUnit',
        dataSource: 'dataSource'
    },
    editFieldPrefixes: [
      {
        label: 'product-confirm',
        isControl: true,
        handler: (row, col) => {
          // The price is column index 8
          const priceSelector = 'product-price#' + row + '#8';
          document.getElementById(priceSelector).removeAttribute('contentEditable');
          document.getElementById(priceSelector).classList.remove('productEditable');

          // The consumption is column index 6
          const consumptionSelector = 'product-consumption#' + row + '#6';
          document.getElementById(consumptionSelector).removeAttribute('contentEditable');
          document.getElementById(consumptionSelector).classList.remove('productEditable');

          // Show the edit element
          const productEditSelector = 'cell-event#product-edit#' + row + '#' + col;
          document.getElementById(productEditSelector).style.display = 'inline';

          // Hide the confirm element
          const productConfirmSelector = 'cell-event#product-confirm#' + row + '#' + col;
          document.getElementById(productConfirmSelector).style.display = 'none';
        },
        ioEvent: 'modifyTransacton',
        ioIndices: {
          transactionIdIdx: 0,
          productTypeIdx: 5,
          productPriceIdx: 8,
          productAmountIdx: 6,
          productUnitIdx: 7
        }
      },
      {
        label: 'product-edit',
        isControl: true,
        handler: (row, col) => {
          // The price is column index 8
          const priceSelector = 'product-price#' + row + '#8';
          document.getElementById(priceSelector).setAttribute('contentEditable', 'true');
          // document.getElementById(priceSelector).classList.add('productEditable');

          // The consumption is column index 6
          const consumptionSelector = 'product-consumption#' + row + '#6';
          document.getElementById(consumptionSelector).setAttribute('contentEditable', 'true');
          document.getElementById(consumptionSelector).classList.add('productEditable');

          // Show the confirm element
          const productConfirmSelector = 'cell-event#product-confirm#' + row + '#' + col;
          document.getElementById(productConfirmSelector).style.display = 'inline';

          // Hide the edit element
          const productEditSelector = 'cell-event#product-edit#' + row + '#' + col;
          document.getElementById(productEditSelector).style.display = 'none';
        }
      },
      {
        label: 'product-clear',
        isControl: true,
        ioEvent: 'removeTransactionModifier',
        ioIndices: {
          transactionIdIdx: 0,
          productTypeIdx: 5,
          productPriceIdx: 8,
          productAmountIdx: 6,
          productUnitIdx: 7
        },
        confirmationMsg: 'You are about to remove your product edits.  This will default to the original guess.  Are you sure?'
      },
      {
        label: 'product-price',
        isField: true,
      },
      {
        label: 'product-consumption',
        isField: true,
      },
    ]
};
