import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-special-categories',
  templateUrl: './special-categories.component.html',
  styleUrls: ['./special-categories.component.css']
})
export class SpecialCategoriesComponent implements OnInit {

  @Output()
  public selectCategory: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void { }

  public select(category: string): void {
    this.selectCategory.emit(category);
  }
}
