import { Component, OnInit, Output, EventEmitter, Input, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';

import { Organization } from '../../services/organization/organization.interface';
import { OrganizationService } from '../../services/organization/organization.service';
import { Country } from '../../services/organization/country.interface';
import { Industry } from '../../services/organization/industry.interface';
import { NavigationService } from 'src/app/services/navigation/navigation.service';

export const CONTEXT_CLAIM = 'claim';
export const CONTEXT_RESOLVE = 'resolve';
export const STATUS_CLAIMED = 'claimed';


@Component({
  selector: 'app-add-organization',
  templateUrl: './add-organization.component.html',
  styleUrls: ['./add-organization.component.css']
})
export class AddOrganizationComponent implements OnInit {
  @Input()
  addContext = CONTEXT_CLAIM;

  @Input()
  countries: Array<Country>;

  @Input()
  industries: any;

  @Input()
  outData: any;

  @Input()
  enableSpecialCategories = false;

  @Input()
  enableSelectOrganizaton? = true;

  industryKeys: Array<string>;

  searchOrganizationForm: FormGroup;

  manualOrganizationForm: FormGroup;

  selectedOrganization: Organization;

  selectedIndustry: Industry;

  claimed = false;

  selected = false;

  manual = false;

  adding = false;

  errors = [];

  @Output()
  selectOrganization: EventEmitter<any>;

  private unitedStatesIndex = 235;

  constructor(
    private el: ElementRef,
    private formBuilder: FormBuilder,
    private organizationService: OrganizationService,
    private navigationService: NavigationService) {

    this.outData = {};

    this.selectOrganization = new EventEmitter<any>();

    this.searchOrganizationForm = this.formBuilder.group({
      searchTerm: ['', Validators.required]
    });

  }

  ngOnInit() {
    this.manualOrganizationForm = this.formBuilder.group({
      name: ['', Validators.required],
      duns: [''],
      industry: [{}, Validators.required],
      address: [''],
      city: ['', Validators.required],
      country: [ this.countries[this.unitedStatesIndex], Validators.required]
    });

    this.manualOrganizationForm.valueChanges.subscribe((form) => {
      if (this.errors.length > 0) {
        this.errors = [];
      }
    });

    this.industryKeys = Object.keys(this.industries);

    this.changeIndustry(0);
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      switchMap(searchText =>
        this.organizationService.findOrganizations(searchText)
      )
    )

  inputFormatter(organization: Organization) {
    return organization.name;
  }

  resultFormatter(organization: Organization) {
    return organization;
  }

  select(organization: Organization) {
    if (this.addContext === CONTEXT_CLAIM) {
      this.organizationService.
        checkOrganizationClaimed(organization.id).
        subscribe((claimed: boolean) => {
          this.claimed = claimed;

          if (!this.claimed) {
            this.selected = true;
            this.selectedOrganization = organization;
          }
        });
    }

    if (this.addContext === CONTEXT_RESOLVE) {
      this.selected = true;
      this.selectedOrganization = organization;
    }
  }

  claim() {
    this.sendOrganization(this.selectedOrganization);
  }

  resolve() {
    this.sendOrganization(this.selectedOrganization);
  }

  addOrganization() {
    this.adding = true;
    this.organizationService.createOrganization({
      name: this.manualOrganizationForm.controls['name'].value,
      address: {
        addressLine1: this.manualOrganizationForm.controls['address'].value,
        city: this.manualOrganizationForm.controls['city'].value,
        country: this.manualOrganizationForm.controls['country'].value
      },
      registrationNumbers: [
        {
          classificationType: 'naics',
          classification: this.selectedIndustry.naicsCode
        }
      ],
    }).subscribe((result: Organization | Array<string>) => {
      this.adding = false;

      if (Array.isArray(result)) {
        this.focusInput('organization-name');
        this.errors = result;
      } else {
        this.sendOrganization(result);
      }
    });
  }

  changeIndustry(selectedIndex: number) {
    let count = -1;

    Object.keys(this.industries).forEach((group: string) => {
      this.industries[group].forEach((industry: Industry) => {
        if (++count === selectedIndex) {
          this.selectedIndustry = industry;
        }
      });
    });
  }

  searchFieldChange(searchTerm: string) {
    if (searchTerm.trim()) {
      return;
    }
    this.resetSearch();
  }

  keyPress(event: any) {
    const evt = (event) ? event : ((event) ? event : null);
    const node = (evt.target) ? evt.target : ((evt.srcElement) ? evt.srcElement : null);
    if ((evt.keyCode === 13) && (node.type === 'text')) { return false; }
  }

  toggleManual() {
    this.manual = !this.manual;
    this.resetSearch();
  }

  sendOrganization(organization: Organization) {
    this.outData['organization'] = organization;
    this.selectOrganization.emit(this.outData);
  }

  selectSpecialCategory(category: string) {
    this.outData['specialCategory'] = category;
    this.sendOrganization(this.selectedOrganization);
  }

  goToSelectOrganizations(): void {
    this.navigationService.go('/select-organization');
  }

  private resetSearch() {
    this.claimed = false;
    this.selected = false;
    this.selectedOrganization = null;
  }

  private focusInput(id: string) {
    const invalidControl = this.el.nativeElement.querySelector('#' + id);
    if (invalidControl) {
      invalidControl.focus();
    }
  }
}
