import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppConfiguration } from '../../app.configuration';
import { Token } from './token.interface';
import { User } from './user.interface';
import { StripeCheckoutSession } from '../payments/stripe-checkout-session.interface';

export const viewPerms = {
  viewCps: 'view-cps',
  viewAdmin: 'view-admin',
  loadSuppliers: 'view-load-suppliers',
  connectData: 'view-connect-data',
  productDetails: 'view-product-details',
  purchaseDetails: 'view-purchase-details',
  supplierSummary: 'view-supplier-summary',
  engageSuppliers: 'view-engage-suppliers',
  renewableEnergyVerify: 'view-renewable-energy-verify',
  renewableEnergyStatus: 'view-renewable-energy-status'
};

@Injectable()
export class UserService {

  public tokenRefreshing = false;

  constructor(
    private httpClient: HttpClient,
    private appConfiguration: AppConfiguration,
    private injector: Injector,
  ) { }

  signIn(email: string, password: string): Observable<Token | Array<string>> {
    this.signOut();

    return this.httpClient.post<object>(
      this.appConfiguration.endpoints['getToken'],
      { email, password },
      {
        observe: 'body',
        responseType: 'json'
      }
    ).pipe(
      map((token: Token) => {
        sessionStorage.setItem(this.appConfiguration.tokenKey, JSON.stringify(token));
        return token;
      }),
      catchError(this.handleError)
    );
  }

  refreshToken(refreshToken: string): Observable<Token | Array<string>> {
    const item = sessionStorage.getItem(this.appConfiguration.tokenKey);

    this.tokenRefreshing = true;

    return this.httpClient.put<Token>(
      this.appConfiguration.endpoints['getToken'],
      { refreshToken },
      {
        observe: 'body',
        responseType: 'json'
      }
    ).pipe(
      map((token: Token) => {
        sessionStorage.setItem(this.appConfiguration.tokenKey, JSON.stringify(token));
        this.tokenRefreshing = false;
        return [];
      }),
      catchError(this.handleError)
    );

  }

  setToken() {
    return this.tokenRefreshing;
  }

  getUserFromSession(): User {
    const user = sessionStorage.getItem(this.appConfiguration.userKey);
    if (user) {
      return JSON.parse(user);
    }
  }

  getUser(userId?: number): Observable<User | Array<string>> {
    const user = sessionStorage.getItem(this.appConfiguration.userKey);
    if (user) {
      return of(JSON.parse(user));
    }

    return this.httpClient.get<User>(
      this.appConfiguration.endpoints['getUser'] + '/' + userId,
      {
        observe: 'body',
        responseType: 'json'
      }
    ).pipe(
      map((user: User) => {
        sessionStorage.setItem(this.appConfiguration.userKey, JSON.stringify(user));
        return user;
      }),
      catchError(this.handleError)
    );
  }

  updateUser(user: User): Observable<User | Array<string>> {
    sessionStorage.setItem(this.appConfiguration.userKey, JSON.stringify(user));

    return this.httpClient.patch<User>(
      this.appConfiguration.endpoints.usersApi + '/user/' + user.id,
      {
        id: user.id,
        selectedOrganizationId: user.preferences.selectedOrganizationId
      },
      {
        observe: 'body',
        responseType: 'json'
      }
    ).pipe(
      map((user: User) => {
        sessionStorage.setItem(this.appConfiguration.tokenKey, JSON.stringify(user.token))
        sessionStorage.setItem(this.appConfiguration.userKey, JSON.stringify(user));
        return user;
      }),
      catchError(this.handleError)
    );
  }

  signUp(
    client: string,
    userName: string,
    email: string,
    firstName: string,
    lastName: string,
    password: string): Observable<Array<string>> {
    return this.httpClient.post<User>(
      this.appConfiguration.endpoints['createUser'] + '/' + client,
      {
        firstName,
        lastName,
        userName,
        email,
        password
      },
      {
        observe: 'body',
        responseType: 'json'
      }
    ).pipe(
      map((user: User) => {
        sessionStorage.setItem(this.appConfiguration.tokenKey, JSON.stringify(user.token))
        sessionStorage.setItem(this.appConfiguration.userKey, JSON.stringify(user));
        return [];
      }),
      catchError(this.handleError)
    );
  }

  signOut(): void {
    sessionStorage.clear();
  }

  requestPassword(email: string, client?: string): Observable<Array<string>> {
    const params = {
      email
    };

    if (client) {
      params['client'] = client;
    }

    return this.httpClient.post(
      this.appConfiguration.endpoints['requestPassword'],
      params,
      {
        observe: 'body',
        responseType: 'json'
      }
    ).pipe(
      map(() => []),
      catchError(this.handleError)
    );
  }

  resetPassword(code: string, password: string): Observable<Array<string>> {
    return this.httpClient.patch(
      this.appConfiguration.endpoints['requestPassword'] + '/' + code,
      { password },
      {
        observe: 'body',
        responseType: 'json'
      }
    ).pipe(
      map(() => []),
      catchError(this.handleError)
    );
  }

  confirmUser(code: string): Observable<Array<string>> {
    return this.httpClient.patch(
      this.appConfiguration.endpoints['confirmUser'] + '/' + code,
      {
        observe: 'body',
        responseType: 'json'
      }
    ).pipe(
      map(() => []),
      catchError(this.handleError)
    );
  }

  canAccess(permission: string): boolean {
    const user = JSON.parse(sessionStorage.getItem(this.appConfiguration.userKey));
    return user.scopes.indexOf(permission) != -1;
  }

  refreshClient(client) {
    this.appConfiguration.setClient();
  }

  cacheSession(sessionKey: string, sessionParams: any) {
    sessionStorage.setItem(sessionKey, JSON.stringify(sessionParams));
  }

  getCheckoutSession(sessionId: string): Observable<any> {
    return this.httpClient.get<any>(
      this.appConfiguration.endpoints.usersApi + `/stripe/checkout-session?checkoutSessionId=${sessionId}`,
      {
        observe: 'body',
        responseType: 'json'
      }
    ).pipe(
      map((user: User) => {
        sessionStorage.setItem(this.appConfiguration.tokenKey, JSON.stringify(user.token))
        sessionStorage.setItem(this.appConfiguration.userKey, JSON.stringify(user));
        return user;
      }),
      catchError(this.handleError)
    );
  }

  getUserFromSessionId(userId: number, sessionId: string): Observable<User> {
    return this.httpClient.get<any>(
      this.appConfiguration.endpoints.usersApi + `/stripe/user/${userId}/${sessionId}`,
      {
        observe: 'body',
        responseType: 'json'
      }
    ).pipe(
      map((user: any) => {
        sessionStorage.setItem(this.appConfiguration.tokenKey, JSON.stringify(user.token));
        sessionStorage.setItem(this.appConfiguration.userKey, JSON.stringify(user));
        return user;
      })
    );
  }

  private handleError(error: HttpErrorResponse): Observable<Array<string>> {
    if (error.status === 404 || error.status === 400) {
      return of(['The account was not found.']);
    }

    if (error.status === 409) {
      return of(['A user with the same user name and/or email already exists.']);
    }

    if (error.status === 401) {
      return of(['These credentials do not match our records.']);
    }

    return of((error.error.errors));
  }
}
