import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppConfiguration } from '../../app.configuration';
import { ProductService } from './product.service';
import { Observable } from 'rxjs';

@Injectable()
export class CurrencyResolverService implements Resolve<Array<string>> {

  constructor(
    private appConfiguration: AppConfiguration,
    private productService: ProductService) { }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Array<string>> {
    const currencies = this.appConfiguration.payableCurrencies;

    if (currencies) {
      return this.productService.getCurrencies(currencies);
    } else {
      return this.productService.getCurrencies([]);
    }
  }
}
