import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { AppConfiguration } from '../../app.configuration';
import { ReportParams } from './report-params.interface';

@Injectable()
export class ReportsService {

  constructor(
    private httpClient: HttpClient,
    private appConfiguration: AppConfiguration) {}

  public getReport(organizationId: number, params: ReportParams): Observable<any | Array<string>> {
    const queryParams: URLSearchParams = new URLSearchParams();

    Object.keys(params).forEach(key => {
      queryParams.set(key, params[key]);
    });

    return this.httpClient.get<any>(
        this.appConfiguration.endpoints['insightSummaryReports'].
        replace('%organizationId%', organizationId.toString()) +
        (queryParams ? '?' + queryParams.toString() : ''),
        {
            observe: 'response'
        }
    ).pipe(
        map((reportData: any) => {
            return reportData.body;
        }),
        catchError(this.handleError)
    );
  }

  public getWorkingSupplierMetrics(organizationId: number, affiliate: string): Observable<any | Array<string>> {
    return this.httpClient.get<any>(
      this.appConfiguration.endpoints['summaryReport'].
      replace('%organizationId%', organizationId.toString()).
      replace('%affiliate%', affiliate),
      {
        observe: 'body',
        responseType: 'json'
      }
    ).pipe(
      map((data: object) => {
        return data['data'];
      }),
      map((result: any) => {
        return result;
      }),
      catchError(this.handleError)
    );
  }

  public getGraphData(
    organizationId: number,
    startDate: string,
    endDate: string) {
    return this.httpClient.get<any>(
      this.appConfiguration.endpoints['graphData'].
        replace('%organizationId%', organizationId.toString()) +
        '?organizationId=' + organizationId +
        '&startDate=' + startDate +
        '&endDate=' + endDate,
        {
          observe: 'response'
        }
      ).pipe(
        map((response: any) => {
            return response.body;
        }),
        map((graphData: any) => {
            return graphData;
        }),
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse): Observable<Array<string>> {
    return of((error.error.errors));
  }
}
