import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AppConfiguration } from '../../app.configuration';
import { NavigationService } from '../../services/navigation/navigation.service';
import { Organization } from '../../services/organization/organization.interface';
import { OrganizationService } from '../../services/organization/organization.service';
import { User } from '../../services/user/user.interface';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-select-company',
  templateUrl: './select-company.component.html',
  styleUrls: ['./select-company.component.css']
})
export class SelectCompanyComponent implements OnInit {
  user: User;

  organizationList: Array<any>;

  logo: string;

  logoClass: string;

  organizationForm: FormGroup;

  selectedOrganizationId: number;

  constructor(
    private appConfiguration: AppConfiguration,
    private formBuilder: FormBuilder,
    private navigationService: NavigationService,
    private organizationSerivce: OrganizationService,
    private userService: UserService) {
    this.logo = this.appConfiguration.logo;
    this.logoClass = this.appConfiguration.logoClass;

    this.userService.getUser().subscribe((user: User) => {
      this.user = user;
      this.organizationList = user.organizations;
      this.organizationSerivce.organization.next(null);

      if (this.organizationList.length > 0) {
        this.selectedOrganizationId = this.organizationList[0].organizationId;
      }

      this.organizationForm = this.formBuilder.group({
        organization: ['', Validators.required],
      });
    });
  }

  ngOnInit(): void { }

  addNewOrganization(): void {
    this.navigationService.go('/choose-organization');
  }

  goToSignIn(): void {
    this.navigationService.go('/sign-in');
  }

  goToSelectOrganizations(): void {
    this.navigationService.go('/select-organization');
  }

  async selectOrganization(selectedIndex: number) {
    if (selectedIndex > 0) {
      const selectedOrganizationId =
        this.organizationList[(selectedIndex - 1)].organizationId;

      this.user.preferences.selectedOrganizationId = selectedOrganizationId;

      await this.userService.updateUser(this.user);

      this.organizationSerivce.getOrganization(selectedOrganizationId)
        .subscribe((organization: Organization) => {
          this.navigationService.go('/home');
        });
    }
  }
}
