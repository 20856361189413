import { Component, OnInit } from '@angular/core';

import { NavigationService } from '../../services/navigation/navigation.service';

@Component({
  selector: 'app-server-error',
  templateUrl: './server-error.component.html',
  styleUrls: ['./server-error.component.css']
})
export class ServerErrorComponent implements OnInit {

  constructor(private navigationService: NavigationService) { }

  ngOnInit(): void {
  }

  public goToHome() {
    this.navigationService.go('/');
  }

}
