import { Component, OnInit } from '@angular/core';

import { NavigationService } from '../../services/navigation/navigation.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {
  constructor(private navigationService: NavigationService) { }

  ngOnInit(): void {
  }

  goToHome() {
    this.navigationService.go('/home');
  }
}
