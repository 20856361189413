import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';

import { switchMap } from 'rxjs/operators';

import { OrganizationService } from '../../services/organization/organization.service';
import { NavigationService } from '../../services/navigation/navigation.service';
import { UserService } from '../../services/user/user.service';
import { User } from 'src/app/services/user/user.interface';
import { Organization } from 'src/app/services/organization/organization.interface';

@Component({
  selector: 'app-purchase-confirmation',
  templateUrl: './purchase-confirmation.component.html',
  styleUrls: ['./purchase-confirmation.component.css']
})
export class PurchaseConfirmationComponent implements OnInit {
  private userId = null;
  private orgId = null;
  private sessionId: string;

  constructor(
    private userService: UserService,
    private navigationService: NavigationService,
    private organizationService: OrganizationService,
    private router: Router,
    private route: ActivatedRoute) {

    this.route.queryParamMap.subscribe(
      async (params: any) => {
        this.sessionId = params.params.sessionId;

        this.navigationService.clearQueryParams();

        this.userService.getCheckoutSession(this.sessionId)
          .subscribe((user: User) => {
            this.organizationService.clearSelectedOrganization();
          });
      });
  }

  ngOnInit(): void {}

  goToDashboard() {
    this.navigationService.go('/home/dashboard');
  }
}
