import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';

import { Feature } from './feature.interface';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css']
})
export class SideBarComponent implements OnInit {
  @Input()
  public logo?: string;

  @Input()
  public logoClass?: string;

  @Input()
  public features?: Array<Feature>;

  @Input()
  public selected?: string;

  @Output()
  public changeSelection?: EventEmitter<Feature>;

  constructor(private router: Router) {
    this.changeSelection = new EventEmitter<Feature>();
  }

  ngOnInit(): void {
    this.selected = this.router.routerState.snapshot.url;
  }

  select(feature: Feature) {
    this.selected = feature.route;
    this.changeSelection.emit(feature);
  }
}
