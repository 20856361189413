import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PayRoutingModule } from './pay-routing.module';
import { ProductSelectorModule } from '../../components/product-selector/product-selector.module';
import { LoadingSpinnerModule } from '../../components/loading-spinner/loading-spinner..module';
import { StripeCardModule } from '../../components/stripe-card/stripe-card.module';

import { PayComponent } from './pay.component';

import { ProductService } from '../../services/product/product.service';
import { CurrencyResolverService } from '../../services/product/currency-resolver.service';
import { ProductResolverService } from '../../services/product/product-resolver.service';

@NgModule({
  declarations: [
    PayComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingSpinnerModule,
    ProductSelectorModule,
    PayRoutingModule,
    StripeCardModule
  ],
  exports: [
    PayComponent
  ],
  providers: [
    ProductService,
    ProductResolverService,
    CurrencyResolverService
  ]
})
export class PayModule { }


