import { Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { AppConfiguration } from '../../../app/app.configuration';
import { NavigationService } from '../../services/navigation/navigation.service';
import { UserService } from 'src/app/services/user/user.service';
import { OrganizationService } from 'src/app/services/organization/organization.service';
import { User } from 'src/app/services/user/user.interface';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
export class PricingComponent implements OnInit {
  user: User;

  organizationId: number;

  logo: string;

  logoClass: string;

  @ViewChild('pricingPageContainer')
  private pricingPageContainer: ElementRef;

  constructor(
    private appConfiguration: AppConfiguration,
    @Inject(DOCUMENT) private document: Document,
    private navigationService: NavigationService,
    private organizationService: OrganizationService,
    private renderer2: Renderer2,
    private userService: UserService)
  {
    this.logo = this.appConfiguration.logo;
    this.logoClass = this.appConfiguration.logoClass;

    this.user = JSON.parse(sessionStorage.getItem(this.appConfiguration.userKey));
    this.organizationId = parseInt(this.organizationService.getSelectedOrganization());

    if (!this.organizationId) {
      this.navigationService.go('/error');
    }
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.installPricingPage();
  }

  /*
  choosePlan(planName: string): void {
    try {
      const user: User =
        JSON.parse(sessionStorage.getItem(this.appConfiguration.userKey));

      const checkoutSuccessUrl =
        this.appConfiguration.clientSettings.checkoutSuccessUrl + '&organizationId=' + this.organizationId;

      const checkoutFailedUrl =
        this.appConfiguration.clientSettings.checkoutFailedUrl;

      const productCode = this.getProperyFromPlan(planName, 'code');

      this.ecommService.validatePurchaseCode(productCode).pipe(
        switchMap((productId: StripeProductId) => {
          return this.paymentService.createCheckoutSession(
            this.organizationId,
            productId.productId,
            checkoutSuccessUrl,
            checkoutFailedUrl
          )
        })
      ).subscribe((checkoutSession: StripeCheckoutSession) => {
        this.document.location.href = checkoutSession.url;
      });
    } catch (e) {
      this.navigationService.go('/error');
    }
  }
  */

  private installPricingPage() {
    /*
      <stripe-pricing-table pricing-table-id="prctbl_1MAIiF2B8okBWvxaUKOZQkTQ" publishable-key="pk_test_aws6ADuDNEpoj1eRDdrwdtfo">
      </stripe-pricing-table>
    */
    let element = this.renderer2.createElement('stripe-pricing-table');
    element.setAttribute("pricing-table-id", this.appConfiguration.stripePricingPageKey);
    element.setAttribute("publishable-key", this.appConfiguration.stripePublishableKey);
    element.setAttribute("client-reference-id", `${this.user.id}_${this.organizationId}`);
    this.renderer2.appendChild(this.pricingPageContainer.nativeElement, element);
  }
}


