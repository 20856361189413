import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, BehaviorSubject, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppConfiguration } from '../../app.configuration';
import { DataConnection, UpdateDataConnectionParams } from './data-connection.interface';
import { Country } from './country.interface';
import { Industry } from './industry.interface';
import { Organization } from './organization.interface';
import { User } from '../user/user.interface';
import { Token } from '@angular/compiler/src/ml_parser/lexer';
import { ImportDataReport } from './import-data-report.interface';
import { ImpactsDataSummary } from './impacts-data-source.interface';

@Injectable()
export class OrganizationService {

  organization: BehaviorSubject<Organization>;

  constructor(
    private httpClient: HttpClient,
    private appConfiguration: AppConfiguration,
    private injector: Injector) {
    this.organization = new BehaviorSubject<Organization>(null);
  }

  clearSelectedOrganization() {
    localStorage.removeItem('selectedOrg');
  }

  storeSelectedOrganization(organizationId: number) {
    localStorage.setItem('selectedOrg', organizationId.toString());
  }

  getSelectedOrganization() {
    return localStorage.getItem('selectedOrg');
  }

  getOrganization(id: number): Observable<Organization | Array<string>> {
    const organization = this.organization.getValue();
    if (organization && id === organization.id) {
      return of(this.organization.getValue());
    }

    return this.httpClient.get<Organization>(
      this.appConfiguration.endpoints['getOrganization'] + '/' + id,
      {
        observe: 'body',
        responseType: 'json'
      }
    ).pipe(
      map((data: object) => {
        return data['data'];
      }),
      map((organizationObj: Organization) => {
        this.organization.next(organizationObj);
        return organizationObj;
      }),
      catchError(this.handleError)
    );
  }

  findOrganizations(searchTerm: string): Observable<Array<Organization> | Array<string>> {
    const limit = this.appConfiguration.organizationSearchLimit;

    searchTerm = searchTerm.trim();
    if (!searchTerm.trim()) {
      return of([]);
    }

    return this.httpClient.post<Array<Organization>>(
      this.appConfiguration.endpoints['findOrganizations'],
      { searchTerm, limit },
      {
        observe: 'body',
        responseType: 'json'
      }
    ).pipe(
      map((data: object) => {
        return data['data'];
      }),
      map((organizations: Array<Organization>) => {
        return organizations;
      }),
      catchError(this.handleError)
    );
  }

  updateConfirmationStatus(organizationId: number, ledgerName: string, status: boolean): Observable<any | Array<string>> {
    const url = this.appConfiguration.endpoints['updateConfirmationStatus'].
      replace('%organizationId%', organizationId.toString()) + '?supplierName=' + encodeURIComponent(ledgerName);

    return this.httpClient.put<any>(
      url,
      { status },
      {
        observe: 'body',
        responseType: 'json'
      }
    ).pipe(
      map((data: object) => {
        return data['data'];
      }),
      map((result: any) => {
        return result;
      }),
      catchError(this.handleError)
    );
  }

  checkOrganizationClaimed(organizationId: number): Observable<boolean | Array<string>> {
    return this.httpClient.get<Organization>(
      this.appConfiguration.endpoints['adminState'].
        replace('%organizationId%', organizationId.toString()),
      {
        observe: 'body',
        responseType: 'json'
      }
    ).pipe(
      map((data: object) => {
        return data['data'].status;
      }),
      map((status: string) => {
        return status === 'claimed';
      }),
      catchError(this.handleError)
    );
  }

  getCountries(): Observable<Array<Country> | Array<string>> {
    return this.httpClient.get<Array<Country>>(
      this.appConfiguration.endpoints['countries'],
      {
        observe: 'body',
        responseType: 'json'
      }
    ).pipe(
      map((data: any) => {
        return data['data'];
      }),
      map((countries: Array<Country>) => {
        return countries;
      }),
      catchError(this.handleError)
    );
  }

  getIndustries(): Observable<Array<Industry> | Array<string>> {
    return this.httpClient.get<Array<Industry>>(
      this.appConfiguration.endpoints['industries'],
      {
        observe: 'body',
        responseType: 'json'
      }
    ).pipe(
      map((data: object) => {
        return data['data'];
      }),
      map((industries: Array<Industry>) => {
        return industries;
      }),
      catchError(this.handleError)
    );
  }

  updateSelectedOrganization(id: number, selectedOrganizationId: number): Observable<User | Array<string>> {
    return this.httpClient.patch<User>(
      this.appConfiguration.endpoints['userPreferences'] + '/' + id,
      {
        id,
        selectedOrganizationId
      },
      {
        observe: 'body',
        responseType: 'json'
      }
    ).pipe(
      map((user: User) => {
        return user;
      }),
      catchError(this.handleError)
    );

  }

  updateOrganizationStatus(organizationId: number, status: string): Observable<Token | Array<string>> {
    return this.httpClient.put<Token>(
      this.appConfiguration.endpoints['claimOrganization'].replace('%organizationId%', organizationId.toString()),
      { status },
      {
        observe: 'body',
        responseType: 'json'
      }
    ).pipe(
      map((token: Token) => {
        sessionStorage.setItem(this.appConfiguration.tokenKey, JSON.stringify(token));
        return [];
      }),
      catchError(this.handleError)
    );
  }

  updateGreenCompliance(organizationId: number, compliance: string): Observable<void | Array<string>> {
    return this.httpClient.put<void>(
      this.appConfiguration.endpoints['greenCompliance'].replace('%organizationId%', organizationId.toString()),
      { compliance },
      {
        observe: 'body',
        responseType: 'json'
      }
    ).pipe(
      map(() => {
        return;
      }),
      catchError(this.handleError)
    );
  }

  createOrganization(organization: Organization): Observable<Organization | Array<string>> {
    return this.httpClient.post<Organization>(
      this.appConfiguration.endpoints['createOrganization'],
      organization,
      {
        observe: 'body',
        responseType: 'json'
      }
    ).pipe(
      map((data: object) => {
        return data['data'];
      }),
      map((createdOrganization: Organization) => {
        if (createdOrganization.accessToken) {
          sessionStorage.setItem(this.appConfiguration.tokenKey, JSON.stringify(createdOrganization.accessToken));
        }
        return createdOrganization;
      }),
      catchError(this.handleError)
    );
  }

  getUploadedCompaniesStatus(file: File): Observable<ImportDataReport | Array<string>> {
    const formData = new FormData();

    formData.append("files[]", file);

    return this.httpClient.post<ImportDataReport>(
      this.appConfiguration.endpoints['uploadCompanies'],
      formData,
      {
        observe: 'body',
        responseType: 'json'
      }
    ).pipe(
      map((result: ImportDataReport) => {
        return result;
      }),
      catchError(this.handleError)
    );
  }

  uploadData(file: File, endpoint: string): Observable<ImportDataReport | Array<string>> {
    const formData = new FormData();

    formData.append("file", file);

    return this.httpClient.post<ImportDataReport>(
      endpoint,
      formData,
      {
        observe: 'body',
        responseType: 'json'
      }
    ).pipe(
      map((result: ImportDataReport) => {
        return result;
      }),
      catchError(this.handleError)
    );
  }

  getUploadDataStatus(processId: number, endpoint: string): Observable<ImportDataReport | Array<string>> {
    return this.httpClient.get<ImportDataReport>(
      endpoint,
      {
        observe: 'body',
        responseType: 'json'
      }
    ).pipe(
      map((result: ImportDataReport) => {
        return result;
      }),
      catchError(this.handleError)
    );
  }

  importWorkingSuppliers(organizationId: number, affiliate: string, file: string): Observable<boolean | Array<string>> {
    return this.httpClient.post<boolean>(
      this.appConfiguration.endpoints['importWorkingSupplierReport'],
      { organizationId, affiliate, file },
      {
        observe: 'body',
        responseType: 'json'
      }
    ).pipe(
      map((complete: boolean | Array<string>) => {
        return true;
      }),
      catchError(this.handleError)
    );
  }

  createDataConnection(dataProvider: string, organizationId: number, redirectUrl?: string): Observable<DataConnection | Array<string>> {
    return this.httpClient.post<DataConnection>(
      this.appConfiguration.endpoints['createDataConnection'].replace('%organizationId%', organizationId.toString()),
      {
        dataProvider,
        organizationId,
        redirectUrl
      },
      {
        observe: 'body',
        responseType: 'json'
      }
    ).pipe(
      map((data: object) => {
        return data['data'];
      }),
      map((dataConnection: DataConnection) => {
        return dataConnection;
      }),
      catchError(this.handleError)
    );
  }

  updateDataConnection(params: UpdateDataConnectionParams): Observable<DataConnection | Array<string>> {
    return this.httpClient.patch<DataConnection>(
      this.appConfiguration.endpoints['updateDataConnection'].
        replace('%organizationId%', params.organizationId.toString()).
        replace('%connectionId%', params.dataConnectionId.toString()),
      params,
      {
        observe: 'body',
        responseType: 'json'
      }
    ).pipe(
      map((data: object) => {
        return data['data'];
      }),
      map((dataConnection: DataConnection) => {
        return dataConnection;
      }),
      catchError(this.handleError)
    );
  }

  getWorkingSuppliers(organizationId: number, affiliate: string): Observable<any | Array<string>> {
    return this.httpClient.post<any>(
      this.appConfiguration.
        endpoints['getWorkingSupplierReport'].
        replace('%organizationId%', organizationId.toString()).
        replace('%affiliate%', affiliate.toString()),
      {
        observe: 'body',
        responseType: 'json'
      }
    ).pipe(
      map((data: object) => {
        return data['data'];
      }),
      map((report: any) => {
        return report;
      }),
      catchError(this.handleError)
    );
  }

  resolveWorkingSupplier(
    organizationId: number,
    claimedOrganizationId: number,
    suppliedOrganizationName: string,
    affiliate: string): Observable<any | Array<string>> {
    return this.httpClient.put<any>(
      this.appConfiguration.endpoints['updateWorkingSupplier'] + '/' + organizationId.toString(),
      {
        organizationId,
        claimedOrganizationId,
        suppliedOrganizationName,
        affiliate
      },
      {
        observe: 'body',
        responseType: 'json'
      }
    ).pipe(
      map((data: object) => {
        return data['data'];
      }),
      map((result: any) => {
        return result;
      }),
      catchError(this.handleError)
    );
  }

  inviteSupplier(
    affiliate: string,
    organizationId: number,
    supplierName: string,
    email: string): Observable<any | Array<string>> {
    return this.httpClient.post<any>(
      this.appConfiguration.endpoints['inviteSupplier'],
      {

        affiliate,
        organizationId,
        supplierName,
        email
      },
      {
        observe: 'body',
        responseType: 'json'
      }
    ).pipe(
      map(() => {
        return;
      }),
      catchError(this.handleError)
    );
  }

  updateTransactionSupplier(
    organizationId: number,
    currentSupplierLedgerName: string,
    currentSupplierId: number,
    newSupplierId?: number,
    newSupplierName?: string): Observable<any | Array<string>> {
    return this.httpClient.put<any>(
      this.appConfiguration.

        endpoints['resolveTransactionSupplier'].
        replace('%id%', organizationId.toString()),
      {
        term: currentSupplierLedgerName,
        currentSupplierId,
        newSupplierId,
        newSupplierName
      },
      {
        observe: 'response'
      }
    ).pipe(
      map((data: object) => {
        return data['body'].data;
      }),
      map((result: any) => {
        return result;
      }),
      catchError(this.handleError)
    );
  }

  updateTransactionSpecialCategory(
    organizationId: number,
    term: string,
    currentSupplierId: number,
    specialTransactionCategory: string): Observable<any | Array<string>> {
    return this.httpClient.put<any>(
      this.appConfiguration.

        endpoints['resolveTransactionSupplier'].
        replace('%id%', organizationId.toString()),
      {
        term,
        currentSupplierId,
        special: specialTransactionCategory
      },
      {
        observe: 'response'
      }
    ).pipe(
      map((data: object) => {
        return data['body'].data;
      }),
      map((result: any) => {
        return result;
      }),
      catchError(this.handleError)
    );
  }

  updateReportingCurrency(
    organizationId: number,
    currency: string): Observable<Organization | Array<string>> {
    return this.httpClient.put<Organization>(
      this.appConfiguration.

        endpoints['updateReportingCurrency'].
        replace('%id%', organizationId.toString()),
      {
        currency
      },
      {
        observe: 'response'
      }
    ).pipe(
      map((data: object) => {
        return data['body'].data;
      }),
      map((organization: Organization) => {
        this.organization.next(organization);
        return organization;
      }),
      catchError(this.handleError)
    );
  }

  updateGlobalReportInterval(
    organizationId: number,
    startDate?: string,
    endDate?: string): Observable<Organization | Array<string>> {

    const params: any = {};

    if (startDate) {
      params.startDate = startDate;
    }
    if (endDate) {
      params.endDate = endDate;
    }

    return this.httpClient.put<void>(
      this.appConfiguration.
        endpoints['updateGlobalReportInterval'].
        replace('%organizationId%', organizationId.toString()),
      params,
      {
        observe: 'body',
        responseType: 'json'
      }
    ).pipe(
      map(() => {

        const organization: Organization =
          this.organization.getValue();

        if (organization) {
          organization.reportsGlobalStartDate = startDate;
          organization.reportsGlobalEndDate = endDate;
          this.organization.next(organization);
        }

        return organization;
      }),
      catchError(this.handleError)
    );
  }

  updateReportingYear(organizationId: number, reportingYear: string) {
    return this.httpClient.put<Organization>(
      this.appConfiguration.endpoints['updateReportingYear'].
        replace('%organizationId%', organizationId.toString()),
      {
        organizationId,
        reportingYear
      },
      {
        observe: 'response'
      }
    ).pipe(
      map((data: object) => {
        return data['body'].data;
      }),
      map((organization: Organization) => {
        this.organization.next(organization);
        return organization;
      }),
      catchError(this.handleError)
    );
  }

  reset() {
    this.organization = new BehaviorSubject<Organization>(null);
  }

  pushSuppliers(organizationId: number, partner: string): Observable<Array<number> | Array<string>> {
    return this.httpClient.post<any>(
      this.appConfiguration.endpoints['pushSuppliers'].
        replace('%organizationId%', organizationId.toString()).
        replace('%partner%', partner),
      {
        observe: 'body',
        responseType: 'json'
      }
    ).pipe(
      map((data: object) => {
        return data['data'];
      }),
      map((organizationIds: Array<number>) => {
        return organizationIds;
      }),
      catchError(this.handleError)
    );
  }

  getPushedSuppliers(organizationId: number, partner: string): Observable<Array<number> | Array<string>> {
    return this.httpClient.get<any>(
      this.appConfiguration.endpoints['pushSuppliers'].
        replace('%organizationId%', organizationId.toString()).
        replace('%partner%', partner),
      {
        observe: 'body',
        responseType: 'json'
      }
    ).pipe(
      map((data: object) => {
        return data['data'];
      }),
      map((organizationIds: Array<number>) => {
        return organizationIds;
      }),
      catchError(this.handleError)
    );
  }

  modifyTransaction(organizationId: number, transactionId: number, params: any): Observable<ImpactsDataSummary | Array<string>> {
    return this.httpClient.patch<ImpactsDataSummary>(
      this.appConfiguration.endpoints['editProductEstimate'].
        replace('%organizationId%', organizationId.toString()).
        replace('%transactionId%', transactionId.toString()),
      params,
      {
        observe: 'body',
        responseType: 'json'
      }
    ).pipe(
      map((data: object) => {
        return data['data'];
      }),
      map((data: ImpactsDataSummary) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  removeTransactionModifier(organizationId: number, transactionId: number, productType: string): Observable<ImpactsDataSummary | Array<string>> {
    return this.httpClient.delete<ImpactsDataSummary>(
      this.appConfiguration.endpoints['editProductEstimate'].
        replace('%organizationId%', organizationId.toString()).
        replace('%transactionId%', transactionId.toString()) + '?type=' + productType,
      {
        observe: 'body',
        responseType: 'json'
      }
    ).pipe(
      map((data: any) => {
        return data['data'];
      }),
      map((data: ImpactsDataSummary) => {
        return data;
      }),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse): Observable<Array<string>> {
    return of((error.error.errors));
  }
}
