import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TreeSideBarComponent } from './tree-side-bar.component';

@NgModule({
  declarations: [
    TreeSideBarComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    TreeSideBarComponent
  ],
  providers: []
})
export class TreeSideBarModule {}
