import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ChooseOrganizationComponent } from './choose-organization.component';

import { CountryResolverService } from '../../services/organization/country-resolver.service';
import { IndustryResolverService } from '../../services/organization/industry-resolver.service';
import { UserResolverService } from '../../services/user/user-resolver.service';

const routes: Routes = [
  {
    path: '',
    resolve: {
      user: UserResolverService,
      countries: CountryResolverService,
      industries: IndustryResolverService
    },
    component: ChooseOrganizationComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ChooseOrganizationRoutingModule { }
