import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AppConfiguration } from '../../app.configuration';
import { NavigationService } from '../../services/navigation/navigation.service';
import { PaymentService } from '../../services/payments/payment.service';
import { Product } from '../../services/product/product.interface';
import { OrganizationService } from '../../services/organization/organization.service';
import { STATUS_CLAIMED } from '../../components/add-organization/add-organization.component';
import { User } from '../../services/user/user.interface';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-pay',
  templateUrl: './pay.component.html',
  styleUrls: ['./pay.component.css']
})
export class PayComponent implements OnInit {

  public logo: string;

  public logoClass: string;

  public products: Array<Product>;

  public currencies: Array<string>;

  public user: User;

  public productSelected: Product;

  public defaultCurrency: string;

  public priceInfo: { price: number, currency: string };

  public chargeProvider = 'stripe';

  public chargeType = 'one-time purchase';

  public organizationId: number;

  public bypass = false;

  public errors = [];

  constructor(
    private appConfiguration: AppConfiguration,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private paymentService: PaymentService,
    private organizationService: OrganizationService,
    private userService: UserService) {

    this.logo = this.appConfiguration.logo;

    this.logoClass = this.appConfiguration.logoClass;

    this.defaultCurrency = this.appConfiguration.defaultPaymentCurrency;

    this.route.data.subscribe((data: { user: User, products: Array<Product>, currencies: Array<string> }) => {
      this.user = data.user;
      this.products = data.products;
      this.currencies = data.currencies;
    });

    this.route.paramMap.subscribe(
      (params: any) => {
        this.organizationId = parseInt(params.params.organizationId, 10);

        // TODO: Need a validation to check if org id is legit
        if (!this.organizationId) {
          this.navigationService.go('/');
        }

      }
    );

    this.route.queryParamMap.subscribe(
      (params: any) => {
        if (params.params.bypass) {
          this.bypass = !!(params.params.bypass === appConfiguration.clientSettings.bypassCode);
        }
      }
    );
  }

  ngOnInit() {}

  selectProduct(product: Product) {
    this.productSelected = product;
  }

  selectCurrency(info: { price: number, currency: string }) {
    this.priceInfo = info;
  }

  bypassPayment(bypass: boolean) {
    this.claimOrganizaton();
  }

  pay(payment: any) {
    this.paymentService.updateProductPayment(
      payment.productChargeId,
      this.chargeType,
      this.chargeProvider
    ).subscribe((result: any) => {
      if (Array.isArray(result)) {
        this.errors = result;
      } else {
        this.claimOrganizaton();
      }
    });
  }

  private claimOrganizaton() {
    this.organizationService.
    updateSelectedOrganization(this.user.id, this.organizationId).
      subscribe((user: User) => {
        if (Array.isArray(user)) {
          this.navigationService.go('/home');
        } else {
          this.organizationService.
            updateOrganizationStatus(this.organizationId, STATUS_CLAIMED).
            subscribe(() => {
              this.userService.updateUser(user);
              this.navigationService.go('/home');
            });
        }
      });
  }
}

